import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { convertStylesStringToObject, getPrefixField, sendDmp, isMobileFun } from '../../utils/obj-utils';
import ProgressBar from '../../elements/ProgressBar';
import './style.scss';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const tabList = fields?.tabs;
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : 'show360';
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      tabs.map((item, index) => {
        let tabArr = item.fields?.tip?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.default_tab_name?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // 加载iframe
  useEffect(() => {
    const onload = () => {
      setTimeout(() => {
        const iframe = document.createElement('iframe');
        iframe.id = 'show360_iframe';
        iframe.className = 'show360_iframe';
        iframeRef.current = iframe;
        document.querySelector('.show360_v1').prepend(iframe);
        setInit(true);
      }, 0);
    };
    onload();
    window.addEventListener('load', onload);
    return () => {
      window.removeEventListener('load', onload);
    };
  }, []);

  useEffect(() => {
    if (init) {
      document.querySelector('#show360_iframe').src = `${tabList?.length > 0 ? tabList[tabIndex]?.fields?.href.value : fields?.href?.value}${
        window.location.search
      }`;
      document.querySelector('#show360_iframe').onload = () => {
        setLoading(false);
      };
    }
  }, [tabIndex, init]);

  const tabClick = (item, index) => {
    setLoading(true);
    setTabIndex(index);
    window.Dmp.clickAction(fields?.tabsdmp?.value);
    iframeRef.current.src = `${item?.fields?.href?.value}${window.location.search}`;
  };

  return (
    <div
      className="show360_v1"
      ref={containerRef}
      style={convertStylesStringToObject(fields[`${getPrefixField('containerstyle', isMobile)}`]?.value)}
      id={id}
    >
      <div className="hash"></div>
      <div className="tabs" style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}>
        {tabList &&
          tabList.length > 1 &&
          tabList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`item ${index === tabIndex ? 'on' : ''}`}
                  onClick={() => tabClick(item, index)}
                  dangerouslySetInnerHTML={{ __html: item.fields?.title?.value?.replaceHtml() }}
                ></div>
              </React.Fragment>
            );
          })}
      </div>
      {loading && (
        <div className="loadingMask" style={convertStylesStringToObject(fields[`${getPrefixField('loadingstyle', isMobile)}`]?.value)}>
          <ProgressBar />
        </div>
      )}
    </div>
  );
}
