import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';
import './style.scss';

export default function index({ fields, close }) {
  const isMobile = isMobileFun();
  return (
    <>
      {fields[`${getPrefixField('icon', isMobile)}`]?.value ? (
        <div className="close_v2" style={convertStylesStringToObject(fields[`${getPrefixField('closestyle', isMobile)}`]?.value)}>
          <img
            className="icon"
            src={createFilePath(fields[`${getPrefixField('icon', isMobile)}`]?.value)}
            alt="关闭"
            onClick={() => {
              window.Dmp.clickAction(fields?.dmp?.value);
              close && close();
            }}
          />
        </div>
      ) : null}
    </>
  );
}
