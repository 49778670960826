import React, { useState, useEffect } from 'react';
import { Link, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import Details from './details/index';
import Modal from '../../elements/modal';
import DesktopMenu from './desktop-menu';
import { MODAL_ANI_TYPES } from '../../utils/modal-animations';
import temp from '../../temp/config';
import {
  getPrefixField,
  convertStylesStringToObject,
  createFilePath,
  getQueryString,
  setlocationhistory,
  isMobileFun,
  GotoMall
} from '../../utils/obj-utils';
import { dataFetcher } from '../../dataFetcher';
import { GetGmallAppSchemeUrl } from '../../utils/api';
let wx = null;
export const prepareWechatSdk = () => {
  if (!wx) {
    wx = require('weixin-js-sdk');
  }
  return wx;
};
export default function HeaderNav(props) {
  const isMobile = isMobileFun();
  const gwOwncenter = `${temp.app.mall}gwOwn/center`;
  const gwOwnlogin = `${temp.app.mall}gwOwn/login`;
  const [flag2, setFlag2] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag3, setFlag3] = useState(true);
  const [otherFlag, setOtherFlag] = useState(false);
  const [otherDetailsNum, setOtherDetailsNum] = useState(-1);
  const [num, setNum] = useState(-1);
  const [inputFlag, setInputFlag] = useState(false);
  const { fields } = props;
  const { menuList } = JSON.parse(fields.menuData.value.replaceHtml());
  const navList = ['catalog-hcar', 'catalog-buy', 'catalog-customer', 'catalog-make', 'catalog-ghac', 'catalog-mall'];
  const [searchValue, setSearchValue] = useState('');
  const [carIndex, setCarIndex] = useState(0);
  const list = [
    [],
    ['catalog-buy-btn6', 'catalog-buy-mall', 'catalog-buy-show', 'catalog-buy-btn7', 'catalog-buy-btn8', 'catalog-buy-btn10', 'catalog-buy-btn9'],
    [
      'catalog-customer-btn1',
      'catalog-customer-btn2',
      'catalog-customer-manual',
      'catalog-customer-btn9',
      'catalog-customer-btn6',
      'catalog-customer-btn7',
      'catalog-customer-btn11',
      'catalog-customer-btn3',
      'catalog-customer-btn10',
      'catalog-customer-btn15',
      'catalog-customer-btn16'
    ],
    [
      'catalog-make-news',
      'catalog-make-activity',
      'catalog-make-live',
      'catalog-make-center',
      'catalog-make-tech',
      'catalog-make-value',
      'catalog-make-child'
    ],
    ['catalog-ghac-btn1', 'catalog-ghac-responsibility', 'catalog-ghac-visitor', 'catalog-ghac-btn7', 'catalog-ghac-club']
  ];
  const vehiclesData = JSON.parse(fields[getPrefixField('vehiclesData', isMobile)]?.value?.replaceHtml());
  const submenuData = JSON.parse(fields.submenuData.value.replaceHtml());
  const logo = fields[getPrefixField('logo', isMobile)]?.value;
  const menuStyle = convertStylesStringToObject(fields.menuStyle?.value);
  const searchIcon = fields.searchIcon?.value;
  const driveIcon = fields.driveIcon?.value;
  const dealerIcon = fields.dealerIcon?.value;
  const loginIcon = fields[getPrefixField('loginIcon', isMobile)]?.value;
  const loginStyle = convertStylesStringToObject(fields[getPrefixField('loginStyle', isMobile)]?.value);
  const menuIcon = fields.m_menuIcon?.value;

  useEffect(() => {
    const scroll = () => {
      if (document.documentElement.scrollTop > 60) {
        setFlag(false);
        setNum(-1);
        setFlag3(false);
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    function find(dom) {
      if (dom) {
        if (dom.parentNode === document.querySelector('.selectList')) {
          return false;
        } else {
          return find(dom.parentNode); // dom.parentNode作为子元素向上查找它的父元素
        }
      } else {
        return true;
      }
    }
    const touchstart = (e) => {
      if (e.target.className !== 'mobileList' && e.target.className !== 'mobileListImg') {
        if (find(e.target)) {
          setTimeout(() => {
            setFlag(false);
          }, 100);
        }
      }
    };
    window.addEventListener('touchstart', touchstart);
    return () => {
      window.removeEventListener('touchstart', touchstart);
    };
  }, []);
  const [Refresh, setReFresh] = useState(0);
  const [flag4, setFlag4] = useState(true);
  const [init, setInit] = useState(0);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > document.documentElement.clientHeight - 60) {
        setFlag4(false);
        setInit(1);
      } else {
        setFlag4(true);
      }
    });
  });
  function $(val) {
    return document.querySelector(val);
  }
  function backUrl(bool, page) {
    var backurl = document.referrer;
    if (backurl) {
      if (getQueryString('type') === 'home') {
        if (bool) {
          window.location.href = '/member/home';
        } else {
          setTimeout(() => {
            window.history.go(-2);
          }, 500);
        }
      } else {
        setTimeout(() => {
          window.history.go(-2);
        }, 500);
      }
    }
  }
  function getMembermobileValue(mobile) {
    if (mobile) {
      sessionStorage.removeItem('MemberCarInfo');
      sessionStorage.setItem('MembermobileValue', mobile); // 手机号码存到session
      dataFetcher('/api/honda/v1/DMP/GetSafeUserTag?mobile=' + mobile)
        .then(({ data }) => {
          if (data.Status) {
            if (data?.Data?.retCode === 200 && data?.Data?.result.length > 0) {
              sessionStorage.setItem('MemberCarInfo', JSON.stringify(data?.Data?.result)); // 用户信息存到session
              backUrl(true, '-2');
            } else {
              backUrl(false, '-2');
            }
          } else {
            backUrl(false, '-2');
          }
        })
        .catch((error) => {
          backUrl(false, '-2');
        });
    }
  }
  function GetDMPEncodePhoneNo(mobile) {
    if (mobile) {
      dataFetcher('/api/honda/v1/DMP/GetDMPEncodePhoneNo?mobile=' + mobile)
        .then((res) => {
          if (res?.data?.Status) {
            if (res?.data?.Data?.code === 0 && res?.data?.Data?.data?.encodedata) {
              sessionStorage.setItem('MembermobileValue2', mobile);
              sessionStorage.setItem('urlMembermobileValue', res?.data?.Data?.data?.encodedata);
              setlocationhistory(res?.data?.Data?.data?.encodedata, props?.page?.fields?.personalized_enable?.value, 'tel');
            }
          } else {
            return Promise.reject(new Error(res?.data?.ErrorResult?.ErrMsg));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  var eventJson;
  function membermessage(event) {
    // 把子窗口发送过来的数据显示在父窗口中
    if (event.data && event.data?.landed !== undefined) {
      // 保客获取手机号码复制到url(商城)
      if (
        event.data.mobile &&
        (!sessionStorage.getItem('MembermobileValue2') || sessionStorage.getItem('MembermobileValue2') !== event.data.mobile)
      ) {
        GetDMPEncodePhoneNo(event.data.mobile);
      }
      if (event.data.backHome !== undefined && event.data.backHome) {
        $('#centerIframe').setAttribute('src', '');
        backUrl(false, '-1');
      }
      if (event.data.landed) {
        if (event.data.action && (event.data.action === 'login' || event.data.action === 'regist')) {
          getMembermobileValue(event.data.mobile);
        }
      } else {
        sessionStorage.removeItem('MemberCarInfo');
        sessionStorage.removeItem('MembermobileValue');
      }
      if (JSON.stringify(eventJson) !== JSON.stringify(event.data)) {
        eventJson = event.data;
        var val = document.querySelector('.userName');
        var val2 = document.querySelector('.userName2');
        if (event.data.landed) {
          val.innerHTML = val2.innerHTML = event.data.nickName;
          val.classList.add('redUserName');
          val2.classList.add('redUserName');
          if ($('#centerIframe')) {
            $('#centerIframe').setAttribute('src', gwOwncenter);
            if (isMobile) {
              $('#centerIframe').setAttribute('height', document.documentElement.clientHeight + 200);
            } else {
              if (event.data.isDwpMember) {
                $('#centerIframe').setAttribute('height', '1900');
              } else {
                $('#centerIframe').setAttribute('height', '1200');
              }
            }
          }
        } else {
          val.classList.remove('redUserName');
          val2.classList.remove('redUserName');
          val.innerHTML = val2.innerHTML = '请登录';
          if ($('#centerIframe')) {
            $('#centerIframe').setAttribute('src', gwOwnlogin);
            if (isMobile) {
              $('#centerIframe').style.height = '100vh';
            } else {
              $('#centerIframe').setAttribute('height', '550px');
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    const onload = () => {
      setTimeout(() => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.allowFullScreen = '';
        iframe.id = 'iframePopup';
        iframe.width = '0';
        iframe.height = '0';
        iframe.src = `${temp.app.mall}gwOwn/loginStatus?sts=off`;
        iframe.name = 'iframePopup';
        iframe.className = 'iframe_homePage';
        if (document.querySelector('.headerLogo')) {
          document.querySelector('.headerLogo').appendChild(iframe);
        }
      }, 0);
    };
    window.addEventListener('load', onload);
    onload();
    return () => {
      window.removeEventListener('load', onload);
    };
  }, []);
  useEffect(() => {
    var MemberHtmllocation = window.location.href;
    if (getQueryString('MembermobileValue') && MemberHtmllocation.indexOf('/center') !== -1) {
      // url带参
      getMembermobileValue(getQueryString('MembermobileValue'));
    }

    if (window.attachEvent) {
      window.attachEvent(
        'message',
        function (event) {
          membermessage(event);
        },
        false
      );
    } else if (window.addEventListener) {
      window.addEventListener('message', function (event) {
        membermessage(event);
      });
    }
    if ($('#centerIframe')) {
      document.getElementById('centerIframe').onload = function () {
        setTimeout(function () {
          document.getElementById('centerIframe').contentWindow.postMessage(
            {
              source: 2
            },
            gwOwnlogin
          );
        }, 500);
      };
    }
  }, []);
  return (
    <div
      className={`headerBox-homePage ${!flag4 ? 'headerBoxFlag1' : flag4 && init === 1 ? 'headerBoxFlag2' : ''}`}
      style={{
        background: flag4 ? 'rgba(0,0,0,0)' : 'white',
        boxShadow: !flag4 ? '0 -0.025rem 0.675rem 0rem #ccc' : 'none',
        position: props.fields.flag.value ? 'fixed' : 'absolute'
      }}
    >
      <div className="headerBoxLeft">
        <div className="headerLogo">
          <img
            data-action="clickAction"
            data-item="catalog-back"
            data-url="/"
            src={createFilePath(flag4 ? logo || '/homePage/v1/pc/Header/logo.png' : '/common/header/v1/whiteStytle/logo.png')}
            alt="广汽本田"
            onClick={() => {
              window.open('https://www.ghac.cn', '_self');
            }}
          />
        </div>
        <div className="navList">
          {menuList.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  // data-action="hoverAction"
                  // data-item={`${navList[index]}`}
                  // data-url={item.url}
                  id={item.id}
                  name={item.name}
                  field={{ value: item.title }}
                  key={item.id}
                  style={{ ...menuStyle, color: flag4 ? menuStyle?.color || 'white' : 'black' }}
                  onMouseEnter={(e) => {
                    window.Dmp.hoverAction(navList[index]);
                  }}
                  onClick={(e) => {
                    var url = item.url;
                    if (item.title === '车型总览') {
                      setFlag2(true);
                      setOtherFlag(false);
                      window.Dmp.clickAction(navList[index], url);
                    } else if (item.title === '广汽本田商城') {
                      GotoMall(item.url, navList[index], isMobile);
                    } else if (item.target) {
                      if (submenuData[index].length !== 0) {
                        setFlag2(false);
                        setOtherDetailsNum(index);
                        setOtherFlag(true);
                      } else {
                        setFlag2(false);
                        setOtherFlag(false);
                        window.open(item.url, '_blank');
                      }
                      window.Dmp.clickAction(navList[index], url);
                    } else {
                      if (submenuData[index].length !== 0) {
                        setFlag2(false);
                        setOtherDetailsNum(index);
                        setOtherFlag(true);
                      } else {
                        setFlag2(false);
                        setOtherFlag(false);
                        window.open(item.url, '_self');
                      }
                      window.Dmp.clickAction(navList[index], url);
                    }

                    e.preventDefault();
                  }}
                >
                  {item.title}
                </Link>
                {flag3 && item.moreMenu && index === num ? (
                  <div className="moreMenu">
                    {item.moreMenu.map((ite, idx) => {
                      return <div key={idx}>{ite?.context}</div>;
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mobileList">
        <div
          id="centerLink2"
          style={{ ...loginStyle, fontSize: '14px', color: flag4 ? loginStyle?.color || 'white' : 'black' }}
          onClick={() => {
            window.open('/center?type=home', '_self');
          }}
        >
          <img
            data-action="clickAction"
            data-item="catalog-center"
            data-url="/center"
            src={createFilePath(flag4 ? loginIcon || '/common/header/v1/blackStytle/User_02.png' : '/common/header/v1/whiteStytle/user.png')}
            className="nav"
            alt="登录"
            title="登录"
          />
          <p className="userName2" data-action="clickAction" data-item="catalog-center" data-url="/center">
            请登录
          </p>
        </div>
        <img
          alt="广汽本田"
          src={createFilePath(flag4 ? menuIcon || '/common/header/v1/blackStytle/mobile_menu.png' : '/common/header/v1/whiteStytle/mobile_menu.png')}
          onClick={() => {
            window.Dmp.decideAction('catalog-hbtn0', !flag);
            setFlag(!flag);
            setNum(-1);
          }}
          className="mobileListImg"
        />
      </div>
      <div className="iconList">
        <div className="searchContainer">
          {inputFlag ? (
            <input
              type="text"
              className="search"
              onBlur={(e) => {
                window.Dmp.msgAction('catalog-key', e.target.value);
              }}
              onInput={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          ) : (
            ''
          )}
          <img
            data-action="clickAction"
            data-item="catalog-btn2"
            data-url="/search"
            src={createFilePath(
              flag4
                ? searchIcon || '/common/header/v1/blackStytle/Search_Magnifying_Glassex.png'
                : '/common/header/v1/whiteStytle/Search_Magnifying_Glassex.png'
            )}
            className="nav"
            alt="搜索"
            title="搜索"
            onClick={() => {
              if (searchValue !== '') {
                window.open(`/search?keyword=${searchValue}`, '_self');
                setSearchValue('');
                setInputFlag(false);
              } else {
                setInputFlag(!inputFlag);
              }
            }}
          />
        </div>
        <div>
          <img
            data-action="clickAction"
            data-item="catalog-drive"
            data-url="/buy/tools/test-drive"
            src={createFilePath(
              flag4 ? driveIcon || '/common/header/v1/blackStytle/steering_wheel.png' : '/common/header/v1/whiteStytle/steering_wheel.png'
            )}
            className="nav"
            onClick={() => {
              window.open('/buy/tools/test-drive', '_self');
            }}
            alt="预约试驾"
            title="预约试驾"
          />
        </div>
        <div>
          <a
            onClick={() => {
              window.open('/buy/tools/find-dealer');
            }}
          >
            <img
              data-action="clickAction"
              data-item="catalog-dealer"
              data-url="/buy/tools/find-dealer"
              src={createFilePath(flag4 ? dealerIcon || '/common/header/v1/blackStytle/location.png' : '/common/header/v1/whiteStytle/location.png')}
              className="nav"
              alt="特约店"
              title="特约店"
            />
          </a>
        </div>
        <div>
          <a
            data-action="clickAction"
            data-item="catalog-center"
            data-url="/center"
            onClick={() => {
              window.open('/center?type=home', '_self');
            }}
          >
            <img
              data-action="clickAction"
              data-item="catalog-center"
              data-url="/center"
              src={createFilePath(flag4 ? loginIcon || '/common/header/v1/blackStytle/user.png' : '/common/header/v1/whiteStytle/user.png')}
              className="nav"
              alt="登录"
              title="登录"
            />
          </a>
        </div>
        <a
          id="centerLink"
          data-action="clickAction"
          data-html-item="catalog-center"
          data-url="/center"
          style={{ ...loginStyle, color: flag4 ? loginStyle?.color || 'white' : 'black' }}
          onClick={() => {
            window.open('/center?type=home', '_self');
          }}
        >
          <span className="userName">请登录</span>
        </a>
        {flag2 ? <Details key={Math.random()} close={setFlag2} vehiclesData={vehiclesData}></Details> : ''}
        <Modal open={otherFlag} animation={MODAL_ANI_TYPES.none} className="c-breeze-header__dm-modal">
          <DesktopMenu activeIndex={otherDetailsNum} onClose={(e) => setOtherFlag(false)} submenuData={submenuData} />
        </Modal>
        {/* {otherFlag ? <OtherDetails key={Math.random()} num={otherDetailsNum} close={setOtherFlag}></OtherDetails> : ''} */}
      </div>
      {/* 移动端一级导航的显示 */}
      {flag ? (
        <div className="selectList">
          <div className="mobileIconList">
            <div className="searchContainer">
              <img
                data-action="clickAction"
                data-item="catalog-btn2"
                data-url="/search"
                src={createFilePath('/common/header/v1/whiteStytle/Search_Magnifying_Glassex.png')}
                className="nav"
                alt="搜索"
                title="搜索"
                onClick={() => {
                  window.open('/search', '_self');
                }}
              />
            </div>
            <div className="">
              <img
                data-action="clickAction"
                data-item="catalog-drive"
                data-url="/buy/tools/test-drive"
                src={createFilePath('/common/header/v1/whiteStytle/steering_wheel.png')}
                className="nav"
                onClick={() => {
                  window.open('/buy/tools/test-drive', '_self');
                }}
                alt="预约试驾"
                title="预约试驾"
              />
            </div>
            <div className="">
              <a
                className=""
                onClick={() => {
                  window.open('/buy/tools/find-dealer');
                }}
              >
                <img
                  data-action="clickAction"
                  data-item="catalog-dealer"
                  data-url="/buy/tools/find-dealer"
                  src={createFilePath('/common/header/v1/whiteStytle/location.png')}
                  className="nav"
                  alt="特约店"
                  title="特约店"
                />
              </a>
            </div>
          </div>
          {menuList.map((item, index) => {
            return (
              <div key={index} className="">
                <div
                  className=""
                  onClick={() => {
                    setReFresh(1);
                    if (item.title === '广汽本田商城') {
                      GotoMall(item.url, 'catalog-mall', isMobile);
                    } else {
                      window.Dmp.decideAction(
                        navList[index],
                        index === num && flag3 ? false : index === num && !flag3 ? true : index !== num ? true : false
                      );
                      if (index === num) {
                        setNum(index);
                        setFlag3(!flag3);
                      } else {
                        setFlag3(false);
                        setNum(index);
                        setFlag3(true);
                      }
                    }
                  }}
                >
                  <div id={item.id} name={item.name} field={{ value: item.title }} className="">
                    {item.title}
                  </div>
                  {item.moreMenu.length !== 0 ? (
                    <img
                      alt="广汽本田"
                      src={createFilePath(
                        index === num && flag3 ? '/breeze/v1/pc/other/top_arror.png' : '/common/header/v1/whiteStytle/bottomArror.png'
                      )}
                      className=""
                    />
                  ) : (
                    ''
                  )}
                </div>
                {flag3 && item.moreMenu && index === num ? (
                  index !== 0 ? (
                    <div className="moreMenu">
                      {item.moreMenu.map((ite, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {ite?.context === '广汽本田商城' ? (
                              <div
                                onClick={() => {
                                  GotoMall(ite.m_link !== undefined ? ite.m_link : ite.LinkUrl, list[index][idx], isMobile);
                                }}
                              >
                                {ite?.context}
                              </div>
                            ) : (
                              <div
                                data-action="clickAction"
                                data-item={`${list[index][idx]}`}
                                data-url={isMobile && ite.m_link !== undefined ? ite.m_link : ite.LinkUrl}
                                onClick={() => {
                                  if (ite.target) {
                                    window.open(isMobile && ite.m_link !== undefined ? ite.m_link : ite.LinkUrl, '_blank');
                                  } else {
                                    window.open(isMobile && ite.m_link !== undefined ? ite.m_link : ite.LinkUrl, '_self');
                                  }
                                }}
                              >
                                {ite?.context}
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="moreMenu allCar">
                      <div className="">
                        {vehiclesData.map((ite, idx) => {
                          return (
                            <p
                              data-action="clickAction"
                              data-item={ite.id}
                              key={idx}
                              onClick={() => {
                                setCarIndex(idx);
                              }}
                              className={idx === carIndex ? 'carAllActive carClose' : 'carClose'}
                            >
                              {ite.title}
                            </p>
                          );
                        })}
                      </div>
                      <div className="carListMobile">
                        {vehiclesData[carIndex].children.map((it, iex) => {
                          return (
                            <div key={iex} className="">
                              <div className="carLeft">
                                <div className="lookImg">
                                  <img
                                    alt="广汽本田"
                                    src={createFilePath(it.img)}
                                    data-action="clickAction"
                                    data-item={`${it.imgID}`}
                                    data-url={`${it.lookLink}`}
                                    onClick={() => {
                                      window.open(`${it.lookLink}`, '_self');
                                    }}
                                    className=""
                                  />
                                </div>
                                <div className="lookCcontrol">
                                  <div
                                    data-action="clickAction"
                                    data-item={`${it.threeDID}`}
                                    data-url={`${it.threeD}`}
                                    className="lookCar"
                                    onClick={() => {
                                      window.open(it.threeD, '_self');
                                    }}
                                  >
                                    <img
                                      alt="广汽本田"
                                      data-action="clickAction"
                                      data-item={`${it.threeDID}`}
                                      data-url={`${it.threeD}`}
                                      src={createFilePath('/breeze/v1/pc/other/Frame360Black.png')}
                                      className=""
                                    />
                                    <p data-action="clickAction" data-item={`${it.threeDID}`} data-url={`${it.threeD}`} className="">
                                      3D看车
                                    </p>
                                  </div>
                                  {it.immdUrl && (
                                    <div
                                      data-action="clickAction"
                                      data-item={it.immdID}
                                      data-url={`${it.immdUrl}`}
                                      className="immdbtn"
                                      onClick={() => {
                                        window.open(it.immdUrl);
                                      }}
                                    >
                                      {it.immdName ? it.immdName : 'i-MMD'}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="carRight">
                                <div className="">
                                  <p className={it.littleT ? 'exceed' : ''}>{it.name + (it.littleT ? it.littleT : '')}</p>

                                  <p>
                                    {it.price}
                                    {it.priceonly ? '万元' : '万元起'}
                                  </p>
                                </div>
                                <div className="">
                                  <p
                                    data-action="clickAction"
                                    data-item={`${it.carID}`}
                                    data-url={`${it.car_id}`}
                                    onClick={() => {
                                      window.open(`${it.car_id}`, '_self');
                                    }}
                                    className=""
                                  >
                                    预约试驾 &gt;
                                  </p>
                                  <p
                                    data-action="clickAction"
                                    data-item={`${it.lookID}`}
                                    data-url={`${it.lookLink}`}
                                    onClick={() => {
                                      window.open(`${it.lookLink}`, '_self');
                                    }}
                                    className=""
                                  >
                                    了解车型 &gt;
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
