import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { getPrefixField, sendDmp, pcTorem, mbTorem, isMobileFun, createFilePath, getQueryString, GotoMall } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import Details from '../../elements/InnerPageDetail';
import 'swiper/swiper.min.css';
import './style.scss';
import { GetGmallAppSchemeUrl } from '../../utils/api';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [num, setNum] = useState(0);
  const [swiperflag, setSwiperflag] = useState(false);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  // const hosturl = temp.app.deployUrlS3;
  const defaultTab = fields.default_tab_value?.value;
  const [showcode, setShowcode] = useState(false);
  const data = {
    id
  };
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = props?.params?.pagename;
    setShowcode(true);
  };
  useEffect(() => {
    const scroll = () => {
      if (window.navigationPagename === id) {
        setShowcode(true);
      } else {
        setShowcode(false);
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      let tabArr = fields?.tab_value?.value.split(',').map((tItem) => tItem.toLowerCase());
      tabArr.map((item, index) => {
        if (item === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(defaultTab.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setNum(tabInd < 0 ? (defaultTabInd < 0 ? num : defaultTabInd) : tabInd);
      } else {
        setNum(defaultTabInd < 0 ? num : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <div className="activity" id={data.id}>
        <div className="in">
          <div className="tabs">
            <div
              className={num === 0 ? 'item on' : 'item'}
              onClick={() => {
                setNum(0);
                window.Dmp.clickAction('activity-phev');
              }}
            >
              e:PHEV
            </div>
            <div
              className={num === 1 ? 'item on' : 'item'}
              onClick={() => {
                setNum(1);
                window.Dmp.clickAction('activity-oil');
              }}
            >
              锐·T动
            </div>
          </div>
          <div className="con">
            {num === 1 && <div className="boxshow" dangerouslySetInnerHTML={{ __html: fields?.thtml?.value?.replaceHtml() }}></div>}
            {num === 0 && <div className="boxshow" dangerouslySetInnerHTML={{ __html: fields?.phevhtml?.value?.replaceHtml() }}></div>}
            <div className="control">
              <div
                className="btn btn1"
                onClick={() => {
                  if (num === 0 && fields?.phevmallurl?.value) {
                    GotoMall(fields?.phevmallurl?.value, 'activity-phev-mall', isMobile);
                  }
                  if (num === 1 && fields?.tmallurl?.value) {
                    GotoMall(fields?.tmallurl?.value, 'activity-oil-mall', isMobile);
                  }
                }}
              >
                立即下订
              </div>
              <div
                className="btn btn2"
                onClick={() => {
                  setFlag(true);

                  {
                    num === 0 && window.Dmp.clickAction('activity-phev-more');
                  }
                  {
                    num === 1 && window.Dmp.clickAction('activity-oil-more');
                  }
                }}
              >
                展开详情
              </div>
            </div>
            {!isMobile && <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('enterprise', isMobile)}`].value?.replaceHtml() }}></div>}
          </div>
        </div>
        {fields?.istabbg?.value ? (
          <>
            {num === 1 && <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('tbghtml', isMobile)}`].value?.replaceHtml() }}></div>}
            {num === 0 && <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('phevbghtml', isMobile)}`].value?.replaceHtml() }}></div>}
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('tbghtml', isMobile)}`].value?.replaceHtml() }}></div>
        )}
        {/* <img alt="广汽本田" className="bg" src={`${temp.app.deployUrlS3}${fields[getPrefixField('bg', isMobile)]?.value}`}></img> */}
        <div
          className="bg"
          style={{ background: `url(${createFilePath(fields[getPrefixField('bg', isMobile)]?.value)}) no-repeat`, backgroundSize: 'cover' }}
        ></div>
        {/* <img alt="广汽本田" className="bg" src={createFilePath(fields[getPrefixField('bg', isMobile)]?.value)}></img> */}
        {isMobile && (
          <>
            {showcode ? (
              <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('enterprise', isMobile)}`].value?.replaceHtml() }}></div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      {flag && (
        <>
          {num === 1 && <Details routePath={fields?.tdetailurl?.value} close={() => setFlag(false)} />}
          {num === 0 && <Details routePath={fields?.phevdetailurl?.value} close={() => setFlag(false)} />}
        </>
      )}
    </>
  );
}
