import React, { useState, useEffect } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import Details from './details/index';
import state from './otherDetails/state';
import Modal from '../../elements/modal';
import DesktopMenu from './desktop-menu';
import { MODAL_ANI_TYPES } from '../../utils/modal-animations';
import temp from '../../temp/config';
import State from '../Header/details/state.js';
import { GetGmallAppSchemeUrl } from '../../utils/api';
import { createFilePath, isMobileFun, GotoMall } from '../../utils/obj-utils';
// let jsApiListArr = ['updateAppMessageShareData', 'updateTimelineShareData'];
let wx = null;
export const prepareWechatSdk = () => {
  if (!wx) {
    wx = require('weixin-js-sdk');
  }
  return wx;
};
export default function HeaderNav(props) {
  const isMobile = isMobileFun();
  const [flag2, setFlag2] = useState(false);
  const [flag, setFlag] = useState(false);
  const [flag3, setFlag3] = useState(true);
  const [otherFlag, setOtherFlag] = useState(false);
  const [otherDetailsNum, setOtherDetailsNum] = useState(-1);
  const [num, setNum] = useState(-1);
  const [inputFlag, setInputFlag] = useState(false);
  const { fields } = props;
  const { logo, menuList, mobile_menuIcon, iconLIst, bottom_arror } = fields;
  const navList = ['catalog-hcar', 'catalog-buy', 'catalog-customer', 'catalog-make', 'catalog-ghac', 'catalog-mall'];
  const [searchValue, setSearchValue] = useState('');
  const [carIndex, setCarIndex] = useState(0);
  const [opacityFlag, setOpacityFlag] = useState(false);
  const list = [
    [],
    ['catalog-buy-btn6', 'catalog-buy-mall', 'catalog-buy-show', 'catalog-buy-btn7', 'catalog-buy-btn8', 'catalog-buy-btn10', 'catalog-buy-btn9'],
    [
      'catalog-customer-btn1',
      'catalog-customer-btn2',
      'catalog-customer-manual',
      'catalog-customer-btn9',
      'catalog-customer-btn6',
      'catalog-customer-btn7',
      'catalog-customer-btn11',
      'catalog-customer-btn3',
      'catalog-customer-btn10',
      'catalog-customer-btn15',
      'catalog-customer-btn16'
    ],
    [
      'catalog-make-news',
      'catalog-make-activity',
      'catalog-make-word',
      'catalog-make-center',
      'catalog-make-tech',
      'catalog-make-value',
      'catalog-make-child'
    ],
    ['catalog-ghac-btn1', 'catalog-ghac-responsibility', 'catalog-ghac-visitor', 'catalog-ghac-btn7', 'catalog-ghac-club']
  ];
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 60) {
        setOpacityFlag(true);
      } else {
        setOpacityFlag(false);
      }
    });
  }, []);
  const [Refresh, setReFresh] = useState(0);
  return (
    <div className={`headerBox ${props.fields.className.value}`} style={{ opacity: opacityFlag ? 0 : 1, background: props.fields.backColor.value }}>
      <div className="headerBoxLeft">
        <div className="headerLogo">
          <img
            data-action="clickAction"
            data-item="catalog-back"
            data-url="/"
            // src={`${temp.app.deployUrlS3}${logo.value}`}
            src={createFilePath(logo.value)}
            alt="广汽本田"
            onClick={() => {
              window.open('https://www.ghac.cn', '_self');
            }}
          />
        </div>
        <div className="navList">
          {menuList.map((item, index) => {
            return (
              <div key={index}>
                <Link
                  // data-action="hoverAction"
                  // data-item={`${navList[index]}`}
                  // data-url={item.fields.url.value}
                  id={item.id}
                  name={item.name}
                  field={item.fields.title}
                  key={item.id}
                  onMouseEnter={(e) => {
                    window.Dmp.hoverAction(navList[index]);
                  }}
                  onClick={(e) => {
                    var url = item.fields.url.value;
                    if (item.fields.title.value === '车型总览') {
                      setFlag2(true);
                      setOtherFlag(false);
                      window.Dmp.clickAction(navList[index], url);
                    } else if (item.fields.title.value === '广汽本田商城') {
                      GotoMall(item.fields.url.value, navList[index], isMobile);
                    } else if (item.target) {
                      if (state[index].length !== 0) {
                        setFlag2(false);
                        setOtherDetailsNum(index);
                        setOtherFlag(true);
                      } else {
                        setFlag2(false);
                        setOtherFlag(false);
                        window.open(item.fields.url.value, '_blank');
                      }
                      window.Dmp.clickAction(navList[index], url);
                    } else {
                      if (state[index].length !== 0) {
                        setFlag2(false);
                        setOtherDetailsNum(index);
                        setOtherFlag(true);
                      } else {
                        setFlag2(false);
                        setOtherFlag(false);
                        window.open(item.fields.url.value);
                      }
                      window.Dmp.clickAction(navList[index], url);
                    }
                  }}
                  style={{ color: props.fields.fontColor.value }}
                >
                  {item.fields.title.value}
                </Link>
                {flag3 && item.fields.moreMenu && index === num ? (
                  <div className="moreMenu">
                    {item.fields.moreMenu.map((ite, idx) => {
                      return <div key={idx}>{ite?.fields?.context?.value}</div>;
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mobileList">
        <img
          alt="广汽本田"
          data-action="clickAction"
          data-item="catalog-hbtn0"
          data-url={!flag}
          // src={`${temp.app.deployUrlS3}${mobile_menuIcon.value}`}
          src={createFilePath(mobile_menuIcon.value)}
          onClick={() => {
            setFlag(!flag);
          }}
        />
      </div>
      <div className="iconList">
        {/* {state.iconList.map((item) => { */}
        {/*   return ( */}
        <div className="searchContainer">
          {inputFlag ? (
            <input
              type="text"
              className="search"
              onBlur={(e) => {
                window.Dmp.msgAction('catalog-key', e.target.value);
              }}
              onInput={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          ) : (
            ''
          )}
          <img
            data-action="clickAction"
            data-item="catalog-btn2"
            data-url="/search"
            // src={`${temp.app.deployUrlS3}${iconLIst[0].fields.icon.value}`}
            src={createFilePath(iconLIst[0].fields.icon.value)}
            className="nav"
            alt="搜索"
            title="搜索"
            onClick={() => {
              if (searchValue !== '') {
                window.open(`/search?keyword=${searchValue}`);
                setSearchValue('');
                setInputFlag(false);
              } else {
                setInputFlag(!inputFlag);
              }
            }}
          />
        </div>
        <div>
          <img
            data-action="clickAction"
            data-item="catalog-drive"
            data-url="/buy/tools/test-drive"
            // src={`${temp.app.deployUrlS3}${iconLIst[1].fields.icon.value}`}
            src={createFilePath(iconLIst[1].fields.icon.value)}
            className="nav"
            onClick={() => {
              window.open('/buy/tools/test-drive');
            }}
            alt="预约试驾"
            title="预约试驾"
          />
        </div>
        <div>
          <a
            onClick={() => {
              window.open('/buy/tools/find-dealer');
            }}
          >
            <img
              data-action="clickAction"
              data-item="catalog-dealer"
              data-url="/buy/tools/find-dealer"
              // src={`${temp.app.deployUrlS3}${iconLIst[2].fields.icon.value}`}
              src={createFilePath(iconLIst[2].fields.icon.value)}
              className="nav"
              alt="特约店"
              title="特约店"
            />
          </a>
        </div>
        <div>
          <a
            data-action="clickAction"
            data-item="catalog-center"
            data-url="/center"
            onClick={() => {
              window.open('/center', '_self');
            }}
          >
            <img
              data-action="clickAction"
              data-item="catalog-center"
              data-url="/center"
              // src={`${temp.app.deployUrlS3}${iconLIst[3].fields.icon.value}`}
              src={createFilePath(iconLIst[3].fields.icon.value)}
              className="nav"
              alt="登录"
              title="登录"
            />
          </a>
        </div>
        {flag2 ? <Details key={Math.random()} close={setFlag2}></Details> : ''}
        <Modal open={otherFlag} animation={MODAL_ANI_TYPES.none} className="c-breeze-header__dm-modal">
          <DesktopMenu activeIndex={otherDetailsNum} onClose={(e) => setOtherFlag(false)} />
        </Modal>
        {/* {otherFlag ? <OtherDetails key={Math.random()} num={otherDetailsNum} close={setOtherFlag}></OtherDetails> : ''} */}
      </div>
      {/* 移动端一级导航的显示 */}
      {flag ? (
        <div className="selectList">
          <div className="mobileIconList">
            {/* {state.iconList.map((item) => { */}
            {/*   return ( */}
            <div className="searchContainer">
              <img
                data-action="clickAction"
                data-item="catalog-btn2"
                data-url="/search"
                // src={`${temp.app.deployUrlS3}${iconLIst[0].fields.icon.value}`}
                src={createFilePath(iconLIst[0].fields.icon.value)}
                className="nav"
                alt="搜索"
                title="搜索"
                onClick={() => {
                  window.open('/search');
                }}
              />
            </div>
            <div>
              <img
                data-action="clickAction"
                data-item="catalog-drive"
                data-url="/buy/tools/test-drive"
                // src={`${temp.app.deployUrlS3}${iconLIst[1].fields.icon.value}`}
                src={createFilePath(iconLIst[1].fields.icon.value)}
                className="nav"
                onClick={() => {
                  window.open('/buy/tools/test-drive');
                }}
                alt="预约试驾"
                title="预约试驾"
              />
            </div>
            <div>
              <a
                onClick={() => {
                  window.open('/buy/tools/find-dealer');
                }}
              >
                <img
                  data-action="clickAction"
                  data-item="catalog-dealer"
                  data-url="/buy/tools/find-dealer"
                  // src={`${temp.app.deployUrlS3}${iconLIst[2].fields.icon.value}`}
                  src={createFilePath(iconLIst[2].fields.icon.value)}
                  className="nav"
                  alt="特约店"
                  title="特约店"
                />
              </a>
            </div>
            <div>
              <a
                data-action="clickAction"
                data-item="catalog-center"
                data-url="/center"
                onClick={() => {
                  window.open('/center', '_self');
                }}
              >
                <img
                  data-action="clickAction"
                  data-item="catalog-center"
                  data-url="/center"
                  // src={`${temp.app.deployUrlS3}${iconLIst[3].fields.icon.value}`}
                  src={createFilePath(iconLIst[3].fields.icon.value)}
                  className="nav"
                  alt="登录"
                  title="登录"
                />
              </a>
            </div>
          </div>
          {menuList.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setReFresh(1);
                }}
              >
                <div>
                  <Link
                    // href={item.fields.url.value}
                    id={item.id}
                    name={item.name}
                    field={item.fields.title}
                    onClick={(e) => {
                      if (item.fields.title.value === '广汽本田商城') {
                        GotoMall(item.fields.url.value, 'catalog-mall', isMobile);
                      } else {
                        window.open(item.fields.url.value);
                      }
                    }}
                    // target="_blank"
                  >
                    {item.fields.title.value}
                  </Link>
                  {item.fields.moreMenu.length !== 0 ? (
                    <img
                      alt="广汽本田"
                      src={index === num && flag3 ? createFilePath('/breeze/v1/pc/other/top_arror.png') : createFilePath(bottom_arror.value)}
                      onClick={() => {
                        if (index === num) {
                          setNum(index);
                          setFlag3(!flag3);
                        } else {
                          setFlag3(false);
                          setNum(index);
                          setFlag3(true);
                        }
                        window.Dmp.decideAction(navList[index], Refresh === 0 ? true : !flag3);
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                {flag3 && item.fields.moreMenu && index === num ? (
                  index !== 0 ? (
                    <div className="moreMenu">
                      {item.fields.moreMenu.map((ite, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {item.title === '广汽本田商城' ? (
                              <div
                                onClick={() => {
                                  GotoMall(ite.fields.LinkUrl.value, list[index][idx], isMobile);
                                }}
                              >
                                {ite?.fields?.context?.value}
                              </div>
                            ) : (
                              <div
                                data-action="clickAction"
                                data-item={`${list[index][idx]}`}
                                data-url={ite.fields.LinkUrl.value}
                                onClick={() => {
                                  if (ite.target) {
                                    window.open(ite.fields.LinkUrl.value, '_blank');
                                  } else {
                                    window.open(ite.fields.LinkUrl.value);
                                  }
                                }}
                              >
                                {ite?.fields?.context?.value}
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="moreMenu allCar">
                      <div>
                        {State.map((ite, idx) => {
                          return (
                            <p
                              data-action="clickAction"
                              data-item="catalog-hcar-first"
                              data-url={`${ite.title}`}
                              key={idx}
                              onClick={() => {
                                setCarIndex(idx);
                              }}
                              className={idx === carIndex ? 'carAllActive' : ''}
                            >
                              {ite.title}
                            </p>
                          );
                        })}
                      </div>
                      <div className="carListMobile">
                        {State[carIndex].children.map((it, iex) => {
                          return (
                            <div key={iex}>
                              <div className="carLeft">
                                <div className="lookImg">
                                  <img
                                    alt="广汽本田"
                                    src={createFilePath(it.img)}
                                    data-action="clickAction"
                                    data-item={`${it.imgID}`}
                                    data-url={`${it.lookLink}`}
                                    onClick={() => {
                                      window.open(`${it.lookLink}`);
                                    }}
                                  />
                                </div>
                                <div className="lookCcontrol">
                                  <div
                                    data-action="clickAction"
                                    data-item="catalog-hcar-360"
                                    data-url={`${it.threeD}`}
                                    className="lookCar"
                                    onClick={() => {
                                      window.open(it.threeD);
                                    }}
                                  >
                                    <img
                                      alt="广汽本田"
                                      data-action="clickAction"
                                      data-item="catalog-hcar-360"
                                      data-url={`${it.threeD}`}
                                      // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`}
                                      src={createFilePath('/breeze/v1/pc/other/Frame360Black.png')}
                                    />
                                    <p data-action="clickAction" data-item="catalog-hcar-360" data-url={`${it.threeD}`}>
                                      3D看车
                                    </p>
                                  </div>
                                  {it.immdUrl && (
                                    <div
                                      data-action="clickAction"
                                      data-item={it.immdID}
                                      data-url={`${it.immdUrl}`}
                                      className="immdbtn"
                                      onClick={() => {
                                        window.open(it.immdUrl);
                                      }}
                                    >
                                      {it.immdName ? it.immdName : 'i-MMD'}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="carRight">
                                <div>
                                  <p className={it.littleT ? 'exceed' : ''}>{it.name + (it.littleT ? it.littleT : '')}</p>

                                  <p>
                                    {it.price}
                                    {it.priceonly ? '万元' : '万元起'}
                                  </p>
                                </div>
                                <div>
                                  <p
                                    data-action="clickAction"
                                    data-item="catalog-hcar-drive"
                                    data-url={`${it.car_id}`}
                                    onClick={() => {
                                      window.open(`${it.car_id}`);
                                    }}
                                  >
                                    预约试驾 &gt;
                                  </p>
                                  <p
                                    data-action="clickAction"
                                    data-item="catalog-hcar-detail"
                                    data-url={`${it.lookLink}`}
                                    onClick={() => {
                                      window.open(`${it.lookLink}`);
                                    }}
                                  >
                                    了解车型 &gt;
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
