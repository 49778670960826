import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Script from 'react-load-script';
import ReactTooltip from 'react-tooltip';
import './style.scss';
import {
  getPrefixField,
  convertStylesStringToObject,
  sendDmp,
  isEmpty,
  filterHtmlTag,
  createFilePath,
  isInView,
  debounce,
  isMobileFun
} from '../../utils/obj-utils';
import { openCloudCar } from '../../utils/api';
import realibox from '../../utils/realibox';
import Realibox from '../../elements/Realibox';
import ProgressBar from '../../elements/ProgressBar';

let realiboxObj;
var firstbool = true;
export default function index({ fields, eventRef }) {
  if (firstbool) {
    console.log(fields);
    firstbool = false;
  }
  if (fields?.jsondata?.value) {
    var jsonval = fields?.jsondata?.value.replace(/k:/gi, '"k":').replace(/v:/gi, '"v":').replace(/'/g, '"');
    var getjson = JSON.parse(jsonval.replaceHtml());
    fields = getjson;
  }
  const containerRef = useRef(null);
  const realiboxRef = useRef(null);
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [selectedSubColorIndex, setSelectedSubColorIndex] = useState(0);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [scriptLoad, setScriptLoad] = useState(false);
  const [realiboxInit, setRealiboxInit] = useState(false);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [sceneLoadedProgress, setSceneLoadedProgress] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const isMobile = isMobileFun();
  const [disableAction, setDisableAction] = useState(isMobile);
  const style = convertStylesStringToObject(fields[getPrefixField('style', isMobile)]?.value);
  const style1 = convertStylesStringToObject(fields[getPrefixField('style1', isMobile)]?.value);
  const style2 = convertStylesStringToObject(fields[getPrefixField('style2', isMobile)]?.value);
  const style3 = convertStylesStringToObject(fields[getPrefixField('style3', isMobile)]?.value);
  const style4 = convertStylesStringToObject(fields[getPrefixField('style4', isMobile)]?.value);
  const realiboxLibPath = createFilePath(fields[getPrefixField('realiboxLibPath', isMobile)]?.value);
  const sceneId = fields[getPrefixField('sceneId', isMobile)]?.value;
  const timelineId = fields[getPrefixField('timelineId', isMobile)]?.value;
  const rbsPath = createFilePath(fields[getPrefixField('rbsPath', isMobile)]?.value);
  const loadingImage = createFilePath(fields[getPrefixField('loadingImage', isMobile)]?.value);
  const moreImage = createFilePath(fields[getPrefixField('moreImage', isMobile)]?.value);
  const interiorMoreImage = createFilePath(fields[getPrefixField('interiorMoreImage', isMobile)]?.value || moreImage);
  const showMore = fields[getPrefixField('showMore', isMobile)]?.value;
  const menuList = fields[getPrefixField('menuList', isMobile)].map((item) => ({
    ...item.fields,
    colorList: item.fields.colorList.map((item1) => ({ ...item1.fields })),
    menuList: item.fields.menuList.map((item1) => ({ ...item1.fields })),
    buttonList: item.fields.buttonList.map((item1) => ({ ...item1.fields }))
  }));
  const colorList = fields[getPrefixField('colorList', isMobile)].map((item) => ({ ...item.fields }));
  const buttonList = fields[getPrefixField('buttonList', isMobile)].map((item) => ({ ...item.fields }));
  const vehicleType = fields?.vehicleType?.fields?.v?.value;
  const sectionDmp = fields?.sectionDmp;
  const moreDmp = fields?.moreDmp;
  const anchor = fields?.anchor?.value;
  const timestamp = fields?.timestamp?.value;
  const [tabIndex, setTabIndex] = useState(0);
  const [data, _] = useState({
    style,
    style1,
    style2,
    style3,
    style4,
    realiboxLibPath,
    sceneId,
    timelineId,
    rbsPath,
    loadingImage,
    moreImage,
    interiorMoreImage,
    showMore,
    colorList,
    menuList,
    buttonList,
    vehicleType,
    sectionDmp,
    moreDmp,
    anchor,
    isMobile
  });
  const [isinner, setIsinner] = useState(false);
  useEffect(() => {
    if (colorList.length > 0) {
      colorList?.map((item, index) => {
        if (item?.isselect?.value === 'true') {
          setSelectedColorIndex(index);
        }
      });
    }
  }, []);
  useEffect(() => {
    const scroll = () => {
      setDisableAction(!isInView(containerRef) && data?.isMobile);
    };
    window.addEventListener('scroll', scroll);
    let resize;
    if (!isMobile) {
      resize = debounce(() => {
        const bottomContainer = containerRef.current.getElementsByClassName('bottom-container')[0];
        bottomContainer.style.justifyContent = bottomContainer.scrollWidth > bottomContainer.clientWidth ? 'flex-start' : 'center';
      });
      resize();
      window.addEventListener('resize', resize);
    }
    return () => {
      window.removeEventListener('scroll', scroll);
      if (!isMobile) {
        resize.cancel();
        window.removeEventListener('resize', resize);
      }
    };
  }, []);

  useEffect(() => {
    if (!scriptLoad) {
      return;
    }
    realiboxObj = new realibox(realiboxRef.current, timestamp);
    setRealiboxInit(true);
    return () => {
      realiboxObj.destroy();
      realiboxObj = null;
    };
  }, [scriptLoad]);

  useEffect(() => {
    if (!realiboxInit) {
      return;
    }
    loadScene(data?.rbsPath, data?.sceneId, () => {
      runTimeline(data?.timelineId);
    });
  }, [realiboxInit]);

  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data?.sectionDmp);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = data?.anchor || 'show360';
  };

  const loadScene = (rbsPath, sceneId, callback) => {
    setSceneLoadedProgress(0);
    setSceneLoaded(false);
    realiboxObj.loadScene(
      rbsPath,
      sceneId,
      (isFinish) => {
        if (isFinish) {
          setSceneLoaded(true);
          callback && callback();
        }
      },
      (progress) => {
        setSceneLoadedProgress(Math.trunc(progress * 100));
      }
    );
  };

  const preventRealiboxScroll = () => {
    realiboxRef.current.addEventListener(
      'wheel',
      (e) => {
        e.preventDefault();
        return false;
      },
      { passive: false }
    );
  };

  const colorClick = (item, index) => {
    const element = containerRef.current.getElementsByClassName('color-button')[index];
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    sendDmp(item?.dmp, filterHtmlTag(item?.title?.value));
    if (showSubMenu) {
      setSelectedSubColorIndex(index);
    } else {
      setSelectedColorIndex(index);
    }
    changeColorTimeline(index);
    if (item?.reset?.value) {
      resetMenu();
      setForceUpdate(!forceUpdate);
    }
  };

  const _menuClick = (item, index) => {
    if (item?.menuList?.length > 0 || item?.colorList?.length > 0 || item?.buttonList?.length > 0) {
      setShowSubMenu(true);
      setSelectedMenuIndex(index);
    }
    changeMenuTimeline(index);
    if (item?.reset?.value) {
      if (showSubMenu) {
        setSelectedSubColorIndex(0);
        setSelectedMenuIndex(0);
        setShowSubMenu(false);
      } else {
        resetMenu(index);
      }
    }
    setForceUpdate(!forceUpdate);
    console.log(item?.isinner?.value);
    if (item?.isinner?.value) {
      setIsinner(true);
    } else {
      setIsinner(false);
    }
  };

  const menuClick = (item, index) => {
    const value = item?.reverse ? item?.reverseDmpValue?.value : item?.dmpValue?.value;
    sendDmp(item?.dmp, value, '', isEmpty(value) ? 'click' : 'decide');
    if (item?.sceneId?.value && !item?.sceneLoaded && item?.sceneId?.value !== data?.sceneId) {
      loadScene(createFilePath(item?.rbsPath?.value), item?.sceneId?.value, () => {
        item.sceneLoaded = true;
        _menuClick(item, index);
      });
    } else {
      _menuClick(item, index);
    }
  };

  const menuEnter = (item) => {
    sendDmp(item?.hoverDmp, '', '', 'hover');
  };

  const buttonClick = (item, index) => {
    if (item?.showTooltip?.value) {
      if (data?.isMobile) {
        openCloudCar(data?.vehicleType, (link) => {
          sendDmp(item?.dmp, link);
        });
      } else {
        sendDmp(item?.dmp);
      }
    } else {
      if (!isEmpty(item?.link?.value)) {
        window.open(item?.link?.value);
      }
      sendDmp(item?.dmp, item?.link?.value);
    }
  };

  const moreClick = () => {
    sendDmp(data?.moreDmp);
    setDisableAction(false);
  };

  const runTimeline = (timeline, stop = false) => {
    if (!isEmpty(timeline)) {
      JSON.parse(timeline).forEach((item) => {
        item?.timelineList?.forEach((item1) => {
          stop ? realiboxObj.stopInteraction(item?.sceneId, item1) : realiboxObj.runInteraction(item?.sceneId, item1);
        });
      });
    }
  };

  const changeColorTimeline = (index) => {
    let item = data;
    if (showSubMenu) {
      item = item?.menuList[selectedMenuIndex];
      if (selectedSubColorIndex !== index) {
        runTimeline(item?.colorList[selectedSubColorIndex]?.timelineId?.value, true);
      }
    } else {
      if (selectedColorIndex !== index) {
        runTimeline(item?.colorList[selectedColorIndex]?.timelineId?.value, true);
      }
    }
    runTimeline(item?.colorList[index]?.stopTimelineId?.value, true);
    runTimeline(item?.colorList[index]?.timelineId?.value);
  };

  const changeMenuTimeline = (index) => {
    const item = showSubMenu ? data?.menuList[selectedMenuIndex]?.menuList[index] : data?.menuList[index];
    runTimeline(item[(item?.reverse && 'timelineId') || 'reverseTimelineId']?.value, true);
    runTimeline(item[(item?.reverse && 'reverseStopTimelineId') || 'stopTimelineId']?.value, true);
    runTimeline(item[(item?.reverse && 'reverseTimelineId') || 'timelineId']?.value);
    item.reverse = !item.reverse;
  };

  const resetMenu = (current) => {
    data?.menuList?.forEach((item, index) => {
      if (index !== current) {
        item.reverse = false;
      }
    });
  };

  const getSceneContainer = () => {
    return (
      <Realibox
        canvasRef={realiboxRef}
        className={`scene-container ${disableAction ? 'scene-container-disable' : ''} ${isinner ? 'inner' : ''}`}
        style={data?.style1}
      >
        {data?.showMore && disableAction ? (
          <button className="more-button" onClick={moreClick}>
            <img className="more-image" src={showSubMenu ? data?.interiorMoreImage : data?.moreImage} alt="广汽本田" />
          </button>
        ) : null}
      </Realibox>
    );
  };

  const getColorContainer = () => {
    const colorList = showSubMenu ? data?.menuList[selectedMenuIndex]?.colorList : data?.colorList;
    return (
      <div className="color-container">
        <div className="color-background" style={data?.style2} />
        {colorList?.map((item, index) => {
          const style = convertStylesStringToObject(item?.style?.value);
          const selected = (showSubMenu ? selectedSubColorIndex : selectedColorIndex) === index;
          if (selected) {
            if (item?.activeBorderColor?.value) {
              style.borderColor = item?.activeBorderColor?.value;
            }
          } else {
            if (item?.borderColor?.value) {
              style.borderColor = item?.borderColor?.value;
            }
          }
          return (
            <button className="color-button" key={index} onClick={() => colorClick(item, index)}>
              {item?.iconUrl?.value && item?.activeIconUrl?.value ? (
                <img className="color-image" src={createFilePath(selected ? item?.activeIconUrl?.value : item?.iconUrl?.value)} alt="广汽本田" />
              ) : (
                <div className={`color ${selected ? 'color-active' : ''}`} style={style} />
              )}
              <div
                className={`color-text ${selected ? 'color-text-active' : ''}`}
                dangerouslySetInnerHTML={{ __html: item?.title?.value?.replaceHtml() }}
              />
            </button>
          );
        })}
      </div>
    );
  };

  const getMenuContainer = () => {
    const menuList = showSubMenu ? data?.menuList[selectedMenuIndex]?.menuList : data?.menuList;
    return (
      <div className="menu-container">
        <div className="menu-background" style={data?.style3} />
        {menuList?.map(
          (item, index) =>
            !item?.istabs?.value && (
              <button className="menu" key={index} onClick={() => menuClick(item, index)} onMouseEnter={() => menuEnter(item)}>
                <img className="menu-image" src={createFilePath((item?.reverse && item?.reverseImage?.value) || item?.image?.value)} alt="广汽本田" />
                <div
                  className="menu-text"
                  dangerouslySetInnerHTML={{ __html: ((item?.reverse && item?.reverseTitle?.value) || item?.title?.value)?.replaceHtml() }}
                />
              </button>
            )
        )}
      </div>
    );
  };

  const getMenuTopContainer = () => {
    const menuList = showSubMenu ? data?.menuList[selectedMenuIndex]?.menuList : data?.menuList;
    return (
      <div className="menu-tabs">
        {menuList?.map(
          (item, index) =>
            item?.istabs?.value && (
              <React.Fragment key={index}>
                {item?.title?.value === '进入内饰' && <div className="item on">进入外观</div>}
                <div
                  className="item"
                  key={index}
                  onClick={() => menuClick(item, index)}
                  onMouseEnter={() => menuEnter(item)}
                  dangerouslySetInnerHTML={{ __html: ((item?.reverse && item?.reverseTitle?.value) || item?.title?.value)?.replaceHtml() }}
                ></div>
                {item?.title?.value === '进入外观' && <div className="item on">进入内饰</div>}
              </React.Fragment>
            )
        )}
      </div>
    );
  };

  const getButtonContainer = () => {
    const buttonList = showSubMenu ? data?.menuList[selectedMenuIndex]?.buttonList : data?.buttonList;
    return (
      <div className="button-container" style={data?.style4}>
        {buttonList?.map((item, index) => {
          const show = item?.showTooltip?.value && !data?.isMobile;
          const qrCodeImage = item?.qrCodeImage?.value;
          const qrCodeText = item?.qrCodeText?.value;
          return (
            <div className="car-button-container" key={index}>
              <button
                className="car-button"
                style={convertStylesStringToObject(item?.style?.value)}
                dangerouslySetInnerHTML={{ __html: item?.title?.value?.replaceHtml() }}
                onClick={() => buttonClick(item, index)}
                data-tip={show ? qrCodeText : null}
              />
              {show && (
                <ReactTooltip
                  isCapture={true}
                  event="click"
                  eventOff="blur"
                  type="light"
                  className="tooltip"
                  overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
                    const rect1 = currentTarget.getBoundingClientRect();
                    const rect2 = node.getBoundingClientRect();
                    return { top: rect1.top - rect2.height, left: rect1.left + (rect1.width - rect2.width) / 2 };
                  }}
                >
                  <img className="tooltip-image" src={createFilePath(qrCodeImage)} alt="广汽本田" />
                  <div className="tooltip-text" dangerouslySetInnerHTML={{ __html: qrCodeText?.replaceHtml() }} />
                </ReactTooltip>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <section className="show360-detail_v1" ref={containerRef}>
      {data ? (
        <React.Fragment>
          <Script url={data?.realiboxLibPath} onLoad={() => setScriptLoad(true)} />
          <div className="exhibition-hall-v2" id={data?.anchor || 'show360'} style={data?.style}>
            {getMenuTopContainer()}
            {getSceneContainer()}
            <div
              className="bottom-container"
              style={showSubMenu ? convertStylesStringToObject(data?.menuList[selectedMenuIndex]?.menuStyle?.value) : null}
            >
              {getColorContainer()}
              <div className="bottom-right-container">
                {getMenuContainer()}
                {getButtonContainer()}
              </div>
            </div>
          </div>
          {!sceneLoaded ? (
            <div className="loading-container">
              <ProgressBar progress={sceneLoadedProgress} />
            </div>
          ) : null}
        </React.Fragment>
      ) : null}
    </section>
  );
}
