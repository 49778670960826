import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span data-html-data="' +
        (fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '') +
        '" class="' +
        className +
        '"></span>'
      );
    }
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div className="Media_v1" id={id}>
      <div className="hash"></div>
      <div className="con" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
        {fields[`${getPrefixField('title', isMobile)}`]?.value && (
          <div
            className="title"
            style={convertStylesStringToObject(fields[`${getPrefixField('titlestyle', isMobile)}`]?.value)}
            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
          ></div>
        )}
        {fields?.lists.length > 0 && (
          <div className="lists">
            <>
              <Swiper
                ref={swiperRef}
                slidesPerView={isMobile ? 1 : 3}
                className="swiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                pagination={isMobile ? pagination : ''}
                modules={[Pagination, Navigation]}
                navigation={{
                  prevEl: '.Media_v1 .prev',
                  nextEl: '.Media_v1 .next',
                  disabledClass: 'disabled'
                }}
                onInit={(e) => {
                  if (swiperRef?.current?.swiper?.pagination?.bullets && isMobile) {
                    for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
                      swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                        if (item.currentTarget.dataset.htmlData) {
                          window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                        }
                      };
                    }
                  }
                }}
                onSlideChangeTransitionEnd={(e) => {}}
              >
                {fields?.lists.map((item, index) => {
                  return (
                    <SwiperSlide className="item" key={index}>
                      {item?.fields[`${getPrefixField('img', isMobile)}`]?.value && (
                        <img
                          className="img"
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                          src={createFilePath(item?.fields[`${getPrefixField('img', isMobile)}`]?.value)}
                          alt="广汽本田"
                        />
                      )}
                      {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                        <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                      )}
                      <div className="bottom">
                        {item?.fields[`${getPrefixField('btn', isMobile)}`]?.value && (
                          <div
                            className="btn_v1"
                            style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                            dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                            onClick={() => {
                              window.open(item?.fields?.url?.value);
                              window.Dmp.clickAction(item?.fields?.dmp?.value, item?.fields?.url?.value);
                            }}
                          ></div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="next" data-html-item={fields?.nextdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
              <div className="prev" data-html-item={fields?.prevdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}
