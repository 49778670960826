import React, { useEffect, useState, useRef, createRef, useImperativeHandle } from 'react';
import gsap, { Linear } from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import './style.scss';
import { Swiper } from 'swiper';
import { Pagination, Navigation } from 'swiper';
import temp from '../../temp/config';
import Details from '../../elements/InnerPageDetail';
import { getPrefixField, convertStylesStringToObject, sendDmp, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export default function index(props) {
  const { fields, eventRef, params } = props;
  const containerRef = useRef(null);
  const isMobile = isMobileFun();
  const [tabNum, setTabNum] = useState(0);
  const title = fields[getPrefixField('title', isMobile)]?.value;
  const list = fields[getPrefixField('list', isMobile)];
  const inList = fields[getPrefixField('inList', isMobile)];
  // const deployUrlS3 = temp.app.deployUrlS3;
  const sectionDmp = fields.sectionDmp;
  const [imgLoaded, setImgLoaded] = useState(false);
  const [flag, setFlag] = useState(false);
  const [data, _] = useState({ sectionDmp, isMobile, title, list, inList });
  const wheel1Ref = useRef(null);
  const wheel2Ref = useRef(null);
  const sceneRefList = data.list.map(() => createRef());
  const sceneTextRefList = data.list.map(() => createRef());
  const sceneImgRefList = data.list.map(() => createRef());
  const inSceneRef = useRef(null);
  const inSceneRefList = data.inList.map(() => createRef());
  const inPageState = false;
  useImperativeHandle(eventRef, () => {
    return {
      sendEvent
    };
  });

  const sendEvent = () => {
    sendDmp(data.sectionDmp);
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = params?.pagename !== undefined ? params?.pagename : 'price_exterior';
  };

  useEffect(() => {
    for (let i = 0; i < inSceneRefList.length; i++) {
      const swiper = new Swiper(inSceneRefList[i].current.querySelector('.swiper-support'), {
        grabCursor: true,
        modules: [Navigation],
        slidesPerView: 1,
        grid: {
          fill: 'column',
          rows: 1
        },
        navigation: {
          prevEl: inSceneRefList[i].current.querySelector('.swiper-button-prev'),
          nextEl: inSceneRefList[i].current.querySelector('.swiper-button-next'),
          disabledClass: 'swiper-button-disabled'
        }
      });
      swiper.navigation.prevEl.onclick = function () {
        // sendDmp(data?.dmpPointLeft);
        // sendDmp(data.inList[i].fields?.prev_dmp);
      };
      swiper.navigation.nextEl.onclick = function () {
        // sendDmp(data?.dmpPointRight);
        // sendDmp(data.inList[i].fields?.next_dmp);
      };
    }

    return () => {
      swiper.destroy(true, true);
    };
  }, []);
  useEffect(() => {
    if (!data) {
      return;
    }

    const controller = new ScrollMagic.Controller();
    const timeline = gsap.timeline({ paused: true });

    const scene1Timeline = gsap.timeline({
      paused: true,
      onComplete: () => {
        timeline.kill();
      },
      onReverseComplete: () => {
        timeline.kill();
      }
    });

    scene1Timeline
      .fromTo(sceneImgRefList[sceneImgRefList.length - 1].current, { x: '100vw' }, { x: '0', duration: 1.5 })
      .fromTo(wheel1Ref.current, { x: '100vw' }, { x: '0', duration: 1.5 }, '<')
      .fromTo(wheel1Ref.current, { rotate: 0 }, { rotate: -360, duration: 1.5, repeat: 1, ease: Linear.easeNone }, '<')
      .fromTo(wheel2Ref.current, { x: '100vw' }, { x: '0', duration: 1.5 }, '<')
      .fromTo(wheel2Ref.current, { rotate: 0 }, { rotate: -360, duration: 1.5, repeat: 1, ease: Linear.easeNone }, '<')
      .fromTo(sceneTextRefList[sceneTextRefList.length - 1].current, { opacity: 0, y: '100vh' }, { opacity: 1, y: '0', duration: 2 }, '<');
    for (let i = data.list.length - 1; i >= 1; i--) {
      scene1Timeline.fromTo(sceneRefList[i].current, { clip: 'rect(0 100vw 100vh 0)' }, { clip: 'rect(0 100vw 0vh 0)', duration: 1.5 });
    }

    const scene1TimelineTemp = gsap.timeline({
      duration: 1,
      onStart: () => {
        scene1Timeline.timeScale(1);
        scene1Timeline.play();
      },
      onReverseComplete: () => {
        scene1Timeline.timeScale(3);
        scene1Timeline.reverse();
        // scene1Timeline.pause();
      }
    });
    timeline.add(scene1TimelineTemp);
    const scene1 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 0.1,
      duration: document.documentElement.clientHeight / 10
    })
      .on('progress', function (event) {
        timeline.totalProgress(event.progress);
      })
      .setTween(timeline)
      .addTo(controller);
    const scene2Timeline = gsap.timeline({ paused: true });
    scene2Timeline.fromTo(inSceneRef.current, { x: '140vw' }, { x: '0', duration: 3, delay: 5 }, '<');

    const scene2 = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: document.documentElement.clientHeight * data.list.length
    })
      .on('progress', function (event) {
        scene2Timeline.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(scene2Timeline)
      .addTo(controller);
    return () => {
      scene1Timeline.kill();
      scene1TimelineTemp.kill();
      scene2Timeline.kill();
      scene1.remove();
      scene1.destroy();
      scene2.remove();
      scene2.destroy();
      controller.destroy();
    };
  }, [data]);

  useEffect(() => {
    const carImgHeight = sceneImgRefList[data.list.length - 1].current.clientHeight;
    const windowHeight = document.documentElement.clientHeight;
    wheel1Ref.current.style.bottom = data.isMobile ? 1.2 + 'rem' : 0.08 * carImgHeight + 'px';
    wheel2Ref.current.style.bottom = data.isMobile ? 1.2 + 'rem' : 0.08 * carImgHeight + 'px';
  }, [imgLoaded]);

  return (
    <section>
      <div className="exterior_v8" ref={containerRef} id={params?.pagename !== undefined ? params?.pagename : 'price_exterior'}>
        {data.list &&
          data.list.map((item, index) => {
            return (
              <div className="scene" ref={sceneRefList[index]} key={index}>
                <div className="scene_background" style={convertStylesStringToObject(item.fields.background?.value)}>
                  <div className="title" dangerouslySetInnerHTML={{ __html: data.title?.replaceHtml() }} />
                  <div className="scene_text" ref={sceneTextRefList[index]}>
                    <img
                      alt="广汽本田"
                      className="scene_text_img"
                      src={`${createFilePath(item.fields.textImage?.value)}`}
                      style={convertStylesStringToObject(item.fields.textImageStyle?.value)}
                    />
                    <div className="scene_text_1" dangerouslySetInnerHTML={{ __html: item.fields.title?.value?.replaceHtml() }} />
                    <div className="scene_text_2" dangerouslySetInnerHTML={{ __html: item.fields.describe?.value?.replaceHtml() }} />
                    {/* <button
                      className="btnDetails"
                      onClick={() => {
                        sendDmp(item.fields.btn_dmp);
                        setFlag(true);
                      }}
                    >
                      {item.fields.btn?.value}
                    </button> */}
                  </div>
                  <img
                    alt="广汽本田"
                    className="scene_img"
                    ref={sceneImgRefList[index]}
                    src={`${createFilePath(item.fields.carImage?.value)}`}
                    onLoad={() => {
                      index === data.list.length - 1 && setImgLoaded(true);
                    }}
                  />
                  {index === data.list.length - 1 && (
                    <>
                      <img alt="广汽本田" className="scene_wheel1" ref={wheel1Ref} src={`${createFilePath(item.fields.wheelImg?.value)}`} />
                      <img alt="广汽本田" className="scene_wheel2" ref={wheel2Ref} src={`${createFilePath(item.fields.wheelImg?.value)}`} />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        <div className="inScene" ref={inSceneRef}>
          <div className="inPageTab">
            {data.inList &&
              data.inList.map((item, index) => {
                return (
                  <span
                    className={`tabItem ${index === tabNum ? 'act' : ''}`}
                    key={index}
                    onClick={() => {
                      setTabNum(index);
                      sendDmp(item.fields.videoBtnDmp);
                    }}
                  >
                    {item.fields.title?.value}
                  </span>
                );
              })}
          </div>
          {data.inList &&
            data.inList.map((item, index) => {
              return (
                <div ref={inSceneRefList[index]} className={`inSceneItem ${index === tabNum ? '' : 'hide'}`} key={index}>
                  <div className="swiper-container">
                    <div className="swiper-support">
                      <div className="swiper-wrapper">
                        <React.Fragment>
                          {item.fields.list &&
                            item.fields.list.map((item, index) => {
                              return (
                                <div className="swiper-slide" key={index}>
                                  <img
                                    alt="广汽本田"
                                    src={`${createFilePath(item.fields.backgroundImg.value)}`}
                                    style={convertStylesStringToObject(item.fields.imageStyle?.value)}
                                  />
                                  <div className="describeCon" dangerouslySetInnerHTML={{ __html: item.fields.describe?.value?.replaceHtml() }} />
                                </div>
                              );
                            })}
                        </React.Fragment>
                      </div>
                    </div>
                    <div
                      className="swiper-button-prev swiper-button-p"
                      onClick={() => {
                        sendDmp(item.fields.prev_dmp);
                      }}
                    />
                    <div
                      className="swiper-button-next swiper-button-n"
                      onClick={() => {
                        sendDmp(item.fields.next_dmp);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {flag && <Details routePath={data.list[0].fields.btn_href?.value} close={() => setFlag(false)} />}
    </section>
  );
}
