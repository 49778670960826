import React, { useEffect, useState, useRef } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, GotoMall } from '../../utils/obj-utils';
import Detailss from '../../elements/InnerPageDetail';
import './style.scss';
export default function Banner(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [flag, setFlag] = useState(false);
  const [fixflag, setFixFlag] = useState(false);
  useEffect(() => {
    const scroll = () => {
      if (
        document.documentElement.scrollTop > document.documentElement.clientHeight - 60 &&
        document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight * 4.5 > 0
      ) {
        setFixFlag(true);
      } else {
        setFixFlag(false);
      }
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  return (
    fixflag && (
      <div className="FooterFixmeau_v1" style={convertStylesStringToObject(fields[`${getPrefixField('meaustyle')}`]?.value)}>
        <div
          className="configbtn"
          style={convertStylesStringToObject(fields[`${getPrefixField('configbtnstyle', isMobile)}`]?.value)}
          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('configbtn', isMobile)}`]?.value?.replaceHtml() }}
          onClick={() => {
            setFlag(true);
            window.Dmp.clickAction(fields?.configbtndmp?.value);
          }}
        ></div>
        <div
          className="mallbtn"
          style={convertStylesStringToObject(fields[`${getPrefixField('mallbtnstyle', isMobile)}`]?.value)}
          dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('mallbtn', isMobile)}`]?.value?.replaceHtml() }}
          onClick={() => {
            if (fields?.mallurl?.value) {
              GotoMall(fields?.mallurl?.value, fields?.mallbtndmp?.value, isMobile);
            }
          }}
        ></div>
        {flag && <Detailss routePath={fields?.detailurl?.value} close={() => setFlag(false)} />}
      </div>
    )
  );
}
