import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath, GotoMall } from '../../utils/obj-utils';
import './style.scss';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const [tabsflag, setTabsflag] = useState(true);
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const tabClick = (item, index) => {
    setTabsflag(false);
    setTimeout(() => {
      setTabsflag(true);
    }, 10);
    setTabIndex(index);
    window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
  };

  return (
    <div className="Activity_v2" id={id}>
      <div className="hash"></div>
      <div className="con" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
        {fields?.tabs && fields?.tabs.length > 1 && (
          <div
            className={`tabs tabs${fields?.tabs.length}`}
            style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}
          >
            {fields?.tabs.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`item ${index === tabIndex ? 'on' : ''}`}
                    onClick={() => tabClick(item, index)}
                    dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        )}

        <div
          className="lists"
          style={convertStylesStringToObject(fields?.tabs[tabIndex]?.fields[`${getPrefixField('listsstyle', isMobile)}`]?.value)}
        >
          {fields?.tabs.length > 0 &&
            tabsflag &&
            fields?.tabs[tabIndex]?.fields?.lists.length > 0 &&
            fields?.tabs[tabIndex]?.fields?.lists?.map((item, index) => {
              return (
                <div className={`item ${item?.fields?.ismall?.value ? 'mallitem' : ''}`} key={index}>
                  {item?.fields[`${getPrefixField('icon', isMobile)}`]?.value && (
                    <img
                      className="icon"
                      style={convertStylesStringToObject(item?.fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
                      src={createFilePath(item?.fields[`${getPrefixField('icon', isMobile)}`]?.value)}
                      alt="广汽本田"
                    />
                  )}
                  {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                    <div
                      className="right"
                      dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}
                    ></div>
                  )}
                </div>
              );
            })}
        </div>
        {fields?.tabs[tabIndex]?.fields[`${getPrefixField('time', isMobile)}`]?.value && (
          <div
            className="time"
            style={convertStylesStringToObject(fields?.tabs[tabIndex]?.fields[`${getPrefixField('timestyle', isMobile)}`]?.value)}
            dangerouslySetInnerHTML={{ __html: fields?.tabs[tabIndex]?.fields[`${getPrefixField('time', isMobile)}`]?.value?.replaceHtml() }}
          ></div>
        )}
        <div className="bottom">
          {fields?.tabs[tabIndex]?.fields?.btnlists.length > 0 &&
            fields?.tabs[tabIndex]?.fields?.btnlists.map((item, index) => {
              return (
                <div
                  className={`btn_v1 btn_v1_${index + 1}`}
                  key={index}
                  style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                  dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                  onClick={() => {
                    if (item?.fields?.url?.value) {
                      GotoMall(item?.fields?.url?.value, item?.fields?.dmp?.value, isMobile);
                    }
                  }}
                ></div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
