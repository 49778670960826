import React, { useState, useImperativeHandle } from 'react';
import Detailss from '../../elements/InnerPageDetail';
import { getPrefixField, isMobileFun, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : 'kv';
  };

  return (
    <div className="kv_v4" id={id}>
      {/* <div className="hash"></div> */}
      <div className="video" style={convertStylesStringToObject(fields[`${getPrefixField('videostyle', isMobile)}`]?.value)}>
        <img
          alt="广汽本田"
          className="img"
          style={convertStylesStringToObject(fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
          src={`${createFilePath(fields[getPrefixField('img', isMobile)]?.value)}`}
        />
        <img
          alt="play"
          className="icon"
          src={`${createFilePath(fields[getPrefixField('icon', isMobile)]?.value)}`}
          style={convertStylesStringToObject(fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
          onClick={() => {
            setFlag(true);
            window.Dmp.clickAction(fields?.dmp?.value, createFilePath(fields[`${getPrefixField('videourl', isMobile)}`]?.value));
          }}
        />
      </div>
      {flag && (
        <Detailss
          routePath={fields?.detailurl?.value}
          {...fields}
          close={() => setFlag(false)}
          isModal={document.documentElement.clientWidth <= 1024}
        />
      )}
    </div>
  );
}
