import React, { useEffect, useRef, useState } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './style.scss';
import { getPrefixField, sendDmp, scrollFinish, isMobileFun } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields } = props;
  const containerRef = useRef(null);
  const [tabNum, setTabNum] = useState(0);
  const isMobile = isMobileFun();
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const secondPageRef = useRef(null);
  const placeHolderRoute = fields?.placeHolderRoute?.value;
  const tabs = fields[getPrefixField('tabs', isMobile)];
  const [data, _] = useState({
    isMobile,
    tabs,
    placeHolderRoute
  });

  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = 0;
      data.tabs.map((item, index) => {
        if (item.fields?.tab_name?.value === value) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      switch (getQueryVariable('tab')) {
        case 'hybrid':
          setTabNum(queryTabDefault('hybrid'));
          break;
        case 'pet':
          setTabNum(queryTabDefault('pet'));
          break;
        case 'hyrid':
          setTabNum(queryTabDefault('hyrid'));
          break;
        case 'plugin':
          setTabNum(queryTabDefault('plugin'));
          break;
        case 'oil':
          setTabNum(queryTabDefault('oil'));
          break;
        case 'control':
          setTabNum(queryTabDefault('control'));
          break;
        default:
          setTabNum(queryTabDefault(fields?.default_tab_name?.value));
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const list = containerRef.current.querySelectorAll('._s-car-comparison');
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        if (i === tabNum) {
          list[i].style.display = 'block';
          list[i].style.paddingTop = '0.8rem';
        } else {
          list[i].style.display = 'none';
        }
      }
    }
  }, [tabNum]);

  return (
    <section>
      <div className="carComparisonTab_v1" ref={containerRef}>
        <div>
          <div className="tab_list">
            {data.tabs &&
              data.tabs.length > 0 &&
              data.tabs.map((item, index) => {
                return (
                  <>
                    {index !== 0 && <div className="split_line" dangerouslySetInnerHTML={{ __html: fields?.split_line.value?.replaceHtml() }} />}
                    <div
                      key={index}
                      className={`tab_item ${index === tabNum ? 'tab_disabled' : ''}`}
                      onClick={() => {
                        setTabNum(index);
                        sendDmp(item?.fields?.tabDmp);
                        setTimeout(() => {
                          containerRef.current.scrollIntoView();
                          scrollFinish(() => {
                            window.scrollTo(0, window.scrollY + 10);
                          });
                        }, 0);
                      }}
                    >
                      <div
                        className={index === tabNum ? 'tabContainer_active' : 'tabContainer'}
                        dangerouslySetInnerHTML={{ __html: item.fields?.tab?.value?.replaceHtml() }}
                      />
                    </div>
                  </>
                );
              })}
          </div>
          <div ref={secondPageRef}>
            <Placeholder name={data?.placeHolderRoute} rendering={route} page={route} />
          </div>
        </div>
      </div>
    </section>
  );
}
