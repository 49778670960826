import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import Detailss from '../../elements/InnerPageDetail';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [btnindex, setBtnindex] = useState(0);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const tabsswiperRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span data-html-data="' +
        (fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '') +
        '" class="' +
        className +
        '"></span>'
      );
    }
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <>
      <div className="price_exterior_v2" id={id}>
        <div className="hash"></div>
        <div className="con">
          {fields?.lists.length > 0 && (
            <>
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                className="swiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                pagination={!fields?.istabs?.value ? pagination : ''}
                modules={[Pagination, Navigation]}
                navigation={{
                  prevEl: '#' + id + ' .prev',
                  nextEl: '#' + id + ' .next',
                  disabledClass: 'disabled'
                }}
                onInit={(e) => {
                  if (swiperRef?.current?.swiper?.pagination?.bullets && !fields?.istabs?.value) {
                    for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
                      swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                        if (item.currentTarget.dataset.htmlData) {
                          window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                        }
                      };
                    }
                  }
                }}
                onSlideChangeTransitionEnd={(e) => {
                  if (tabsswiperRef.current) {
                    tabsswiperRef.current.swiper.slideTo(e.activeIndex);
                    var allBoxes = tabsswiperRef.current.swiper.slides;
                    for (var i = 0; i < allBoxes.length; i++) {
                      allBoxes[i].className = allBoxes[i].className.replace('on', ' ');
                      allBoxes[e.activeIndex].classList.add('on');
                    }
                  }
                  setSwiperIndex(e.activeIndex);
                }}
              >
                {fields?.lists.map((item, index) => {
                  return (
                    <SwiperSlide className="item" key={index}>
                      {item?.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                        <img
                          className="bg"
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                          src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                          alt="广汽本田"
                        />
                      )}
                      {item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value && (
                        <div
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                          dangerouslySetInnerHTML={{
                            __html: item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value?.replaceHtml()
                          }}
                        ></div>
                      )}
                      {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                        <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                      )}
                      <div className="bottom" style={convertStylesStringToObject(item?.fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}>
                        {item?.fields?.btnlists.length > 0 &&
                          item?.fields?.btnlists.map((btnitem, btnindex) => {
                            return (
                              <React.Fragment key={btnindex}>
                                {(!btnitem?.fields?.isonly?.value ||
                                  (btnitem?.fields?.isonly?.value &&
                                    ((btnitem?.fields?.isonly?.value === 'pc' && !isMobile) ||
                                      (btnitem?.fields?.isonly?.value === 'mb' && isMobile)))) && (
                                  <div
                                    className={`btn_v1 btn_v1_${btnindex + 1}`}
                                    key={btnindex}
                                    style={convertStylesStringToObject(btnitem?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                                    dangerouslySetInnerHTML={{
                                      __html: btnitem?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml()
                                    }}
                                    onClick={() => {
                                      setFlag(true);
                                      setBtnindex(btnindex);
                                      window.Dmp.clickAction(btnitem?.fields?.dmp?.value, createFilePath(btnitem?.fields?.videourl?.value));
                                    }}
                                  ></div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="next" data-html-item={fields?.nextdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
              <div className="prev" data-html-item={fields?.prevdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
            </>
          )}
          {fields?.lists.length > 0 && fields?.istabs?.value && (
            <div
              className={`tabs tabs${fields?.lists.length}`}
              style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}
            >
              <Swiper
                ref={tabsswiperRef}
                slidesPerView={isMobile ? 2 : 3}
                className="tabsswiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                navigation={false}
                centeredSlides={isMobile && swiperIndex !== 0 ? true : false}
              >
                {fields?.lists.map((item, index) => {
                  return (
                    <SwiperSlide
                      className={swiperIndex === index ? 'item on' : 'item'}
                      key={index}
                      onClick={() => {
                        swiperslideTo(index);
                        window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}></div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
        {flag && (
          <Detailss
            routePath={fields?.lists[swiperIndex]?.fields?.btnlists[btnindex]?.fields?.detailurl?.value}
            {...fields?.lists[swiperIndex]?.fields?.btnlists[btnindex]?.fields}
            close={() => setFlag(false)}
          />
        )}
      </div>
    </>
  );
}
