import React, { useState, useEffect, useRef } from 'react';
import FullModals from '../../../elements/FullModals';
import './style.scss';
import temp from '../../../temp/config';
import { createFilePath } from '../../../utils/obj-utils';

export default function index(props) {
  const [num, setNum] = useState(0);
  const [num2, setNum2] = useState(-1);
  const [rightBox1, setRightBox1] = useState(0);
  const rightBigBox = useRef(null);
  const handleRightScroll = (index) => {
    const box1 = document.getElementById('scrollHeader0');
    const box2 = document.getElementById('scrollHeader1');
    const box3 = document.getElementById('scrollHeader2');
    const box4 = document.getElementById('scrollHeader3');
    setRightBox1(index);
    switch (index) {
      case 0:
        box1.style.display = 'grid';
        box2.style.display = 'none';
        box3.style.display = 'none';
        box4.style.display = 'none';
        break;
      case 1:
        box1.style.display = 'none';
        box2.style.display = 'grid';
        box3.style.display = 'none';
        box4.style.display = 'none';
        break;
      case 2:
        box1.style.display = 'none';
        box2.style.display = 'none';
        box3.style.display = 'grid';
        box4.style.display = 'none';
        break;
      case 3:
        box1.style.display = 'none';
        box2.style.display = 'none';
        box3.style.display = 'none';
        box4.style.display = 'grid';
        break;
      default:
        box1.style.display = 'grid';
        box2.style.display = 'none';
        box3.style.display = 'none';
        box4.style.display = 'none';
    }
  };
  var num3 = -1;
  const changeNum3 = (idx) => {
    num3 = idx;
    setNum2(num3);
  };
  useEffect(() => {
    handleRightScroll(rightBox1);
  });

  return (
    <FullModals key={Math.random()}>
      <div className="carLook">
        <div>
          <div className="carLookLeft">
            {props.vehiclesData.map((item, index) => {
              return (
                <p
                  data-action="clickAction"
                  data-item={item.id}
                  className={index === num ? 'activeCar' : ''}
                  onClick={() => {
                    setNum(index);
                    handleRightScroll(index);
                  }}
                  key={index}
                >
                  {item.title}
                </p>
              );
            })}
          </div>
          <div className="carLookRight" ref={rightBigBox}>
            {props.vehiclesData.map((item, index) => {
              return (
                <div key={index} id={`scrollHeader${index}`}>
                  {item.children.map((ite, idx) => {
                    return (
                      <div key={idx}>
                        <div>
                          <p>{ite.name}</p>
                          {ite.littleT ? <p className="LittleT">{ite.littleT}</p> : ''}
                          <p className="wan">
                            {ite.price}
                            <span>{ite.priceonly ? '万元' : '万元起'}</span>
                          </p>
                        </div>
                        <img
                          src={createFilePath(ite.img)}
                          data-action="clickAction"
                          data-item={`${ite.imgID}`}
                          data-url={`${ite.pc_lookLink}`}
                          alt={ite.alt}
                          onClick={() => {
                            window.open(`${ite.pc_lookLink}`, '_self');
                          }}
                        />
                        <div className="lookCcontrol">
                          <div
                            data-action="clickAction"
                            data-item={ite.threeDID}
                            data-url={`${ite.threeD}`}
                            className="lookC"
                            onMouseOver={(e) => {
                              // changeNum3(idx);
                              // e.currentTarget.firstElementChild.src = `${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360White.png`;
                              e.currentTarget.firstElementChild.src = createFilePath('/breeze/v1/pc/other/Frame360White.png');
                            }}
                            onMouseLeave={(e) => {
                              // changeNum3(-1);
                              // e.currentTarget.firstElementChild.src = `${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`;
                              e.currentTarget.firstElementChild.src = createFilePath('/breeze/v1/pc/other/Frame360Black.png');
                            }}
                            onClick={() => {
                              window.open(ite.threeD);
                            }}
                          >
                            {idx === num2 ? (
                              <img
                                alt="广汽本田"
                                data-action="clickAction"
                                data-item={`${ite.threeDID}`}
                                data-url={`${ite.threeD}`}
                                // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360White.png`}
                                src={createFilePath('/breeze/v1/pc/other/Frame360White.png')}
                              />
                            ) : (
                              <img
                                alt="广汽本田"
                                data-action="clickAction"
                                data-item={`${ite.threeDID}`}
                                data-url={`${ite.threeD}`}
                                // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/Frame360Black.png`}
                                src={createFilePath('/breeze/v1/pc/other/Frame360Black.png')}
                              />
                            )}
                            <p data-action="clickAction" data-item={`${ite.threeDID}`} data-url={`${ite.threeD}`}>
                              3D看车
                            </p>
                          </div>
                          {ite.immdUrl && (
                            <div
                              data-action="clickAction"
                              data-item={ite.immdID}
                              data-url={`${ite.immdUrl}`}
                              className="immdbtn"
                              onClick={() => {
                                window.open(ite.immdUrl);
                              }}
                            >
                              {ite.immdName ? ite.immdName : 'i-MMD'}
                            </div>
                          )}
                        </div>
                        <div className="yu">
                          <p
                            data-action="clickAction"
                            data-item={`${ite.carID}`}
                            data-url={`${ite.pc_car_id}`}
                            onClick={() => {
                              window.open(`${ite.pc_car_id}`, '_self');
                            }}
                          >
                            预约试驾 &gt;
                          </p>
                          <p
                            data-action="clickAction"
                            data-item={`${ite.lookID}`}
                            data-url={`${ite.pc_lookLink}`}
                            onClick={() => {
                              window.open(`${ite.pc_lookLink}`, '_self');
                            }}
                          >
                            了解车型 &gt;
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <img
          alt="广汽本田"
          // src={`${temp.app.deployUrlS3}/breeze/v1/pc/other/VectorClose.png`}
          src={createFilePath('/breeze/v1/pc/other/VectorClose.png')}
          onClick={() => {
            props.close(false);
          }}
        />
      </div>
    </FullModals>
  );
}
