import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath, getPathnameByPosition } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import Detailss from '../../elements/InnerPageDetail';
import Details from '../Contrast_v1/details/index';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const swiperRef = useRef(null);
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [tabsflag, setTabsflag] = useState(true);
  const [btnindex, setBtnindex] = useState(0);
  const [carList, setCarList] = useState(null);
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const tabClick = (item, index) => {
    setTabsflag(false);
    setTimeout(() => {
      setTabsflag(true);
    }, 10);
    setTabIndex(index);
    window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span data-html-data="' +
        (fields?.tabs[tabIndex]?.fields?.paginationdmp?.value ? fields?.tabs[tabIndex]?.fields?.paginationdmp?.value + (index + 1) : '') +
        '" class="' +
        className +
        '"></span>'
      );
    }
  };
  return (
    <div className="Detail_v1" id={id}>
      <div className="hash"></div>
      <div className="con" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
        {fields?.tabs && fields?.tabs.length > 1 && (
          <div
            className={`tabs tabs${fields?.tabs.length}`}
            style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}
          >
            {fields?.tabs.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`item ${index === tabIndex ? 'on' : ''}`}
                    onClick={() => tabClick(item, index)}
                    dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        )}
        {fields?.tabs.length > 0 && tabsflag && (
          <>
            {fields?.tabs[tabIndex]?.fields[`${getPrefixField('lo', isMobile)}`]?.value && !isMobile && (
              <img
                className="lo"
                style={convertStylesStringToObject(fields?.tabs[tabIndex]?.fields[`${getPrefixField('lostyle', isMobile)}`]?.value)}
                src={createFilePath(fields?.tabs[tabIndex]?.fields[`${getPrefixField('lo', isMobile)}`]?.value)}
                alt="广汽本田"
              />
            )}
            <div className="lists">
              <>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={isMobile ? 1 : 3}
                  className="swiper"
                  spaceBetween={0}
                  initialSlide={0}
                  observer={true}
                  resizeObserver={true}
                  updateOnWindowResize={true}
                  pagination={isMobile ? pagination : ''}
                  modules={[Pagination, Navigation]}
                  navigation={{
                    prevEl: '.Detail_v1 .prev',
                    nextEl: '.Detail_v1 .next',
                    disabledClass: 'disabled'
                  }}
                  onInit={(e) => {
                    if (swiperRef?.current?.swiper?.pagination?.bullets && isMobile) {
                      for (var i = 0; i < swiperRef.current.swiper.pagination.bullets.length; i++) {
                        swiperRef.current.swiper.pagination.bullets[i].onclick = function (item) {
                          if (item.currentTarget.dataset.htmlData) {
                            window.Dmp.clickAction(item.currentTarget.dataset.htmlData);
                          }
                        };
                      }
                    }
                  }}
                  onSlideChangeTransitionEnd={(e) => {}}
                >
                  {fields?.tabs[tabIndex]?.fields?.lists.map((item, index) => {
                    return (
                      <SwiperSlide className="item" key={index}>
                        {item?.fields[`${getPrefixField('title', isMobile)}`]?.value && (
                          <div
                            className="t"
                            dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                          ></div>
                        )}
                        {fields?.tabs[tabIndex]?.fields[`${getPrefixField('lo', isMobile)}`]?.value && isMobile && (
                          <img
                            className="lo"
                            style={convertStylesStringToObject(fields?.tabs[tabIndex]?.fields[`${getPrefixField('lostyle', isMobile)}`]?.value)}
                            src={createFilePath(fields?.tabs[tabIndex]?.fields[`${getPrefixField('lo', isMobile)}`]?.value)}
                            alt="广汽本田"
                          />
                        )}
                        {item?.fields[`${getPrefixField('car', isMobile)}`]?.value && (
                          <div className="car" style={convertStylesStringToObject(item?.fields[`${getPrefixField('carstyle', isMobile)}`]?.value)}>
                            <img
                              className="img"
                              src={createFilePath(item?.fields[`${getPrefixField('car', isMobile)}`]?.value)}
                              style={convertStylesStringToObject(item?.fields[`${getPrefixField('carimgstyle', isMobile)}`]?.value)}
                              alt="广汽本田"
                            />
                          </div>
                        )}
                        {item?.fields[`${getPrefixField('info', isMobile)}`]?.value && (
                          <div
                            className="info"
                            style={convertStylesStringToObject(item?.fields[`${getPrefixField('infostyle', isMobile)}`]?.value)}
                            dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('info', isMobile)}`]?.value?.replaceHtml() }}
                          ></div>
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div className="next" data-html-item={fields?.tabs[tabIndex]?.fields?.nextdmp?.value}>
                  <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
                </div>
                <div className="prev" data-html-item={fields?.tabs[tabIndex]?.fields?.prevdmp?.value}>
                  <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
                </div>
              </>
            </div>
            <div className="bottom">
              {fields?.tabs[tabIndex]?.fields?.btnlists.length > 0 &&
                fields?.tabs[tabIndex]?.fields?.btnlists.map((item, index) => {
                  return (
                    <div
                      className={`btn_v1 btn_v1_${index + 1}`}
                      key={index}
                      style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                      dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                      onClick={() => {
                        if (item?.fields?.isloan?.value) {
                          setFlag2(true);
                          const carList = fields?.tabs?.reduce(
                            (prev, cur) => [
                              ...prev,
                              ...cur?.fields?.lists?.map((item) => ({
                                carName: item.fields.title_text.value,
                                GuidancePrice: parseFloat(item.fields.price.value)
                              }))
                            ],
                            []
                          );
                          setCarList(carList);
                          console.log(carList);
                        } else {
                          setBtnindex(index);
                          setFlag(true);
                        }
                        window.Dmp.clickAction(item?.fields?.dmp?.value);
                      }}
                    ></div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {flag && <Detailss routePath={fields?.tabs[tabIndex]?.fields?.btnlists[btnindex]?.fields?.detailurl?.value} close={() => setFlag(false)} />}
      {flag2 && <Details key={Math.random()} carType={getPathnameByPosition()?.toLowerCase()} close={setFlag2} carList={carList} />}
    </div>
  );
}
