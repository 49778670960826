import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { getPrefixField, createFilePath, isMobileFun } from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FindDealer from '../../elements/FindDealer';
import { dataFetcher } from '../../dataFetcher';
import $ from 'jquery';
import { getQueryVariable } from '../../utils/site-utils';

export default function index(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const rc_img = fields[getPrefixField('image', isMobile)]?.value;
  const [data, _] = useState({ isMobile, rc_img });
  const [vinValue, setVinValue] = useState('');
  // 车架号弹窗关闭
  const vinShowBoxClose = () => {
    if (document.getElementById('infobox')) {
      document.getElementById('infobox').style.display = 'none';
    }
  };

  // 车架号input查询
  const checkVinValue = (val) => {
    if (val.length !== 17) {
      alert('请正确输入代码，正确代码长度为17位，请重新输入');
      return;
    }

    const vinType = getQueryVariable('type') && getQueryVariable('type').length !== 0 ? getQueryVariable('type') : '38';

    dataFetcher(`/api/honda/v1/Article/ValidateRecall?vin=${val}&batchNo=${vinType}`)
      .then(({ data }) => {
        if (data.Status) {
          document.getElementById('showvin').innerHTML = `${val}`;
          if (data.Data) {
            if (vinType !== '12' && vinType !== '25') {
              let vinShowBox = document.getElementsByClassName('vinShowBox_content')[0];
              let aList = vinShowBox.getElementsByTagName('p');
              if (aList && aList.length > 0) {
                for (let i = 0; i < aList.length; i++) {
                  vinShowBox.removeChild(aList[i]);
                }
              }

              document.getElementById('showtext').innerHTML =
                '您的车辆在本次召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
              if (vinType === '15') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次蓄电池传感器召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
              } else if (vinType === '17') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次后视镜开关召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
              } else if (vinType === '26') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次加速受限召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
              } else if (vinType === '20') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次机油液面增高召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                if (isMobile) {
                  let node = document.createElement('p');
                  node.innerHTML =
                    '<a href="http://dwp.ghac.cn/view/recall/recallCrownJump.aspx?fvin=' +
                    val +
                    '&zh=cn" style="display:  block;background-color: #C00;width:  30%;margin:  auto;color:  #fff;">微信预约</a> ';
                  document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
                }
              } else if (vinType === '21') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次制动总泵召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                if (isMobile) {
                  let node = document.createElement('p');
                  node.innerHTML =
                    '<a href="http://dwp.ghac.cn/view/recall/recallCrownJump.aspx?fvin=' +
                    val +
                    '&zh=cn" style="display:  block;background-color: #C00;width:  30%;margin:  auto;color:  #fff;">微信预约</a> ';
                  document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
                }
              } else if (vinType === '22') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次机油液面增高召回范围之内，请您与广汽Acura特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="http://www.acura.com.cn/dealers/search/" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem;text-decoration:none">查询特约店</a>'
                  : '<a href="http://www.acura.com.cn/dealers/search/" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;">查询特约店</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '27') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次燃油泵召回范围之内，请您与广汽本田特约店/广汽Acura售后服务网点预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a><a href="https://www.acura.com.cn/dealer/service" style="display: block;background-color: #C00;width: 60vw;margin:1rem  auto;color:  #fff;text-align:center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽Acura售后服务网点查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a><a href="https://www.acura.com.cn/dealer/service" style="display: block;background-color: #C00;width: 200px;margin:1rem  auto;color:  #fff;text-align:center;text-decoration:none">广汽Acura售后服务网点查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '28') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次气囊召回范围之内，请与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem;text-decoration: none;">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '29') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次燃油泵召回范围之内（召回正式开始实施时间为：<span style="color:red;font-weight: 700;">2021年3月31日</span>），您可在召回开始实施后与广汽本田特约店/广汽Acura售后服务网点预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a><a href="https://www.acura.com.cn/dealer/service" style="display: block;background-color: #C00;width: 60vw;margin:1rem  auto;color:  #fff;text-align:center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽Acura售后服务网点查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a><a href="https://www.acura.com.cn/dealer/service" style="display: block;background-color: #C00;width: 200px;margin:1rem  auto;color:  #fff;text-align:center;text-decoration:none">广汽Acura售后服务网点查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '30') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次PCU召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '31') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次前视广角摄像头召回范围之内，请您与广汽本田特约销售服务店预约进行软件升级，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '32') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次召回范围之内（召回正式开始实施时间为：<span style="color:red;font-weight: 700;">2023年3月31日</span>），您可以在召回开始实施后与广汽本田特约店预约进行召回检修，或致电广汽Acura客户服务热线400-930-8999或800-830-5999（仅限固话用户）查询售后服务网点再预约，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '33') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次召回范围之内，请您与广汽本田特约销售服务店预约进行EDR系统软件升级，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '34') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次召回范围之内，请您与广汽本田特约销售服务店预约进行电子控制单元（ECU）升级，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '35') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次燃油泵召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '36') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次制动踏板模拟器召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '37') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次蓄电池正极线束召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              } else if (vinType === '38') {
                document.getElementById('showtext').innerHTML =
                  '您的车辆在本次进口NSX燃油泵召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！';
                let node = document.createElement('p');
                node.innerHTML = isMobile
                  ? '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 60vw;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none;padding:0.1rem;font-size:0.3rem">广汽本田特约店查询</a>'
                  : '<a href="/buy/tools/find-dealer" style="display: block;background-color: #C00;width: 200px;margin:1rem auto ;color:  #fff;text-align: center;text-decoration: none">广汽本田特约店查询</a>';
                document.getElementsByClassName('vinShowBox_content')[0].appendChild(node);
              }
            } else {
              var msg =
                '您的车辆在本次气囊气体发生器召回范围之内，请您与广汽本田特约销售服务店预约进行召回检修，感谢您对本次召回活动的支持与理解！您的车辆需要更换的零件如下：';
              document.getElementById('showtext').innerHTML = msg;
            }
            document.getElementById('infobox').style.display = 'block';
          } else {
            if (vinType === '13' || vinType === '14' || vinType === '22') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次召回范围，请您放心使用。';
            } else if (vinType === '15') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次蓄电池传感器召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '17') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次后视镜开关召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '20') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次机油液面增高召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '21') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次制动总泵召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '23') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次MDX行李箱尾盖灯召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '26') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次加速受限召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '27') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次燃油泵召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '28') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次气囊召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '29') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次燃油泵召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '30') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次PCU召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '31') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次前视广角摄像头召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '32') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '33') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次EDR系统软件升级召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '34') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次发动机电子控制单元（ECU）升级召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '35') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次燃油泵召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '36') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次制动踏板模拟器召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '37') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次蓄电池正极线束召回范围，请您放心使用，祝您用车愉快！';
            } else if (vinType === '38') {
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次进口NSX燃油泵召回范围，请您放心使用，祝您用车愉快！';
            } else {
              console.log(vinType);
              document.getElementById('showtext').innerHTML = '您的车辆不属于本次气囊气体发生器召回范围，请您放心使用，祝您用车愉快！';
            }
            document.getElementById('infobox').style.display = 'block';
            let vinShowBox = document.getElementsByClassName('vinShowBox_content')[0];
            let aList = vinShowBox.getElementsByTagName('p');
            if (aList && aList.length > 0) {
              for (let i = 0; i < aList.length; i++) {
                vinShowBox.removeChild(aList[i]);
              }
            }
          }
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 车辆查询显示
  useEffect(() => {
    const type1 = getQueryVariable('type') && getQueryVariable('type').length !== 0 ? getQueryVariable('type') : '38';
    if (type1 === '12') {
      document.getElementById('span_title').innerHTML = '广汽本田气体发生器召回对象车辆查询<br />';
    } else if (type1 === '15') {
      document.getElementById('recallTitle').innerHTML = '2017年蓄电池传感器召回查询';
    } else if (type1 === '17') {
      document.getElementById('recallTitle').innerHTML = '广汽本田后视镜开关召回对象查询';
    } else if (type1 === '18' || type1 === '19') {
      document.getElementById('recallTitle').innerHTML = '广汽本田气体发生器召回对象车辆查询';
    } else if (type1 === '21') {
      document.getElementById('recallTitle').innerHTML = '制动总泵召回车辆查询';
    } else if (type1 === '20') {
      document.getElementById('recallTitle').innerHTML = '机油液面增高召回车辆查询';
    } else if (type1 === '22') {
      document.getElementById('recallTitle').innerHTML = '机油液面增高召回车辆查询';
    } else if (type1 === '23') {
      document.getElementById('recallTitle').innerHTML = 'MDX行李箱尾盖灯召回查询';
    } else if (type1 === '25') {
      document.getElementById('recallTitle').innerHTML = '广汽本田气体发生器召回对象车辆查询';
    } else if (type1 === '26') {
      document.getElementById('recallTitle').innerHTML = '广汽本田部分雅阁行驶中加速受限召回对象车辆查询';
    } else if (type1 === '27') {
      document.getElementById('recallTitle').innerHTML = '广汽本田燃油泵召回对象车辆查询';
      document.getElementById('recalltips3').innerHTML =
        '<ul style="line-height: 1.5;"><li>开始实施时间：2020年6月1日</li><li>召回对象：2018年7月1日至2018年12月31日期间生产的部分国产Acura TLX-L、Acura CDX、Acura RDX、锋范、奥德赛、冠道、缤智、飞度、凌派、雅阁系列汽车</li></ul>';
    } else if (type1 === '28') {
      document.getElementById('recallTitle').innerHTML = '广汽本田气囊召回对象车辆查询';
    } else if (type1 === '29') {
      document.getElementById('recallTitle').innerHTML = '广汽本田燃油泵召回对象车辆查询';
      document.getElementById('recalltips3').innerHTML =
        '<ul style="line-height: 1.5;"><li>开始实施时间：2021年3月31日</li><li>召回对象：2019年1月2日至2019年9月26日期间生产的部分国产Acura TLX-L、Acura CDX、Acura RDX、锋范、奥德赛、冠道、缤智、飞度、凌派、雅阁系列汽车</li></ul>';
    } else if (type1 === '30') {
      document.getElementById('recallTitle').innerHTML = '广汽本田PCU召回对象车辆查询';
    } else if (type1 === '31') {
      document.getElementById('recallTitle').innerHTML = '广汽本田前视广角摄像头召回对象车辆查询';
    } else if (type1 === '32') {
      document.getElementById('recallTitle').innerHTML = '广汽本田召回对象车辆查询';
    } else if (type1 === '33') {
      document.getElementById('recallTitle').innerHTML = '广汽本田EDR系统软件升级召回对象车辆查询';
    } else if (type1 === '34') {
      if (isMobile) {
        document.getElementById('recallTitle').innerHTML = '广汽本田发动机电子控制单元（ECU）升级<br>召回对象车辆查询';
      } else {
        document.getElementById('recallTitle').innerHTML = '广汽本田发动机电子控制单元（ECU）升级召回对象车辆查询';
      }
    } else if (type1 === '35') {
      document.getElementById('recallTitle').innerHTML = '广汽本田燃油泵召回对象车辆查询';
      document.getElementById('recalltips3').innerHTML =
        '<ul style="line-height: 1.5;"><li>开始实施时间：2024年8月30日</li><li>召回对象：<br>(一) 2017年7月22日至2018年6月30日期间生产的部分国产Acura TLX-L、Acura CDX、锋范、奥德赛、雅阁、冠道、缤智、飞度、凌派系列汽车；<br>(二) 2018年3月1日至2023年10月31日期间售后维修时更换过燃油泵的部分国产Acura TLX-L、Acura CDX、Acura RDX、锋范、奥德赛、雅阁、冠道、皓影、缤智、飞度、凌派、歌诗图、理念S1系列汽车；<br>(三) 2018年3月1日至2023年10月31日期间售后维修时更换过燃油泵的部分进口Acura RDX、Acura MDX、Acura TLX系列汽车。</li></ul>';
    } else if (type1 === '36') {
      document.getElementById('recallTitle').innerHTML = '广汽本田制动踏板模拟器召回对象车辆查询';
    } else if (type1 === '37') {
      document.getElementById('recallTitle').innerHTML = '广汽本田蓄电池正极线束召回对象车辆查询';
    } else if (type1 === '38') {
      document.getElementById('recallTitle').innerHTML = '广汽本田进口NSX燃油泵召回对象车辆查询';
    } else {
      document.getElementById('span_title').innerHTML = '';
    }
  }, []);

  const setPersonchild = (e) => {};

  return (
    <section>
      <div className="serviceRecall_v1">
        <div className="header">
          <div className="title">召回查询</div>
          <div className="line" />
        </div>
        <div className="content">
          <div className="content_left">
            <div className="text">
              <p id="recallTitle" className="recallTitle" />
              <p id="recalltips3" className="recalltips3" />
              <p id="span_title" className="recallTitle" />
              <p className="text_vin">车架号（VIN）范例</p>
              <p className="recallCode">LHGRB3808A8000000</p>
              <input id="typeInput" value={vinValue} onChange={(event) => setVinValue(event.target.value)} placeholder="在此输入车架号" />
              <img
                alt="广汽本田"
                className="searchImg"
                src={`${createFilePath('/service/recall/rcsearch.jpg')}`}
                onClick={() => checkVinValue(vinValue)}
              />
              <div className="prompt">
                <span>提示：输入车架号时请使用半角英文输入，输入错误 </span>
                {!data.isMobile && <br />}
                <span>的车架号可能导致查询结果不准确。</span>
              </div>
              <div className="describe">
                <span>车架号（即车辆识别代号）可以在您的</span>
                {!data.isMobile && <br />}
                <span>行驶证或者保修手册中找到（如图所示）</span>
              </div>
            </div>
          </div>
          <div className="content_right">
            <img alt="广汽本田" className="right_img" src={`${createFilePath(data.rc_img)}`} />
          </div>
        </div>
        <div className="content1">
          <FindDealer istabs={false} props={props} setPersonparent={setPersonchild} />
        </div>
        <div className="vinShowBox" id="infobox">
          <div className="vinShowBox_bg" />
          <div className="vinShowBox_content">
            <img
              alt="广汽本田"
              src={`${createFilePath('/service/recall/vinShowBox_close.jpg')}`}
              className="vinShowBox_close"
              onClick={() => vinShowBoxClose()}
            />
            <div id="showvin" className="showvin" />
            <br />
            <div id="showtext" className="showtext" />
          </div>
        </div>
      </div>
    </section>
  );
}
