import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, getPrefixField, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const swiperRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;

  // sectionAction
  useEffect(() => {
    if (
      (fields?.placeHolderRoute?.value && !fields?.placeHolderRoute1?.value) ||
      (!fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value)
    ) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else if (fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  return (
    <div id={id}>
      <div className="price_space_v1" ref={rootEl}>
        <div className="hash"></div>
        <div className="con">
          {fields[`${getPrefixField('bg', isMobile)}`]?.value && (
            <img
              className="bg"
              style={convertStylesStringToObject(fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
              src={createFilePath(fields[`${getPrefixField('bg', isMobile)}`]?.value)}
              alt="广汽本田"
            />
          )}
          {fields[`${getPrefixField('text', isMobile)}`]?.value && (
            <div className="right" dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
          )}
          <div className="control">
            {fields?.lists.length > 0 &&
              fields?.lists.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      window.Dmp.clickAction(item?.fields?.dotdmp?.value, item?.fields?.dmpname?.value ? item?.fields?.dmpname?.value : '');
                      setSwiperIndex(index);
                      windowhidden();
                      setFlag(true);
                    }}
                    className={`dot dot${index + 1}`}
                    style={convertStylesStringToObject(fields[`${getPrefixField('dotstyle', isMobile)}`]?.value)}
                  ></div>
                );
              })}
          </div>
        </div>
        {flag && (
          <>
            <div className="prop_detail">
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={0}
                initialSlide={swiperIndex}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                modules={[Navigation]}
                navigation={{
                  prevEl: '.price_space_v1 .prop_detail .prev',
                  nextEl: '.price_space_v1 .prop_detail .next',
                  disabledClass: 'disabled'
                }}
                onInit={(e) => {}}
                onTransitionStart={(e) => {}}
                onSlideChangeTransitionEnd={(e) => {}}
              >
                {fields?.lists.map((item, index) => {
                  return (
                    <SwiperSlide className="item" key={index}>
                      <div
                        className="close"
                        onClick={() => {
                          setFlag(false);
                          closewindowhidden();
                          window.Dmp.clickAction(item?.fields?.closedmp?.value, item?.fields?.dmpname?.value ? item?.fields?.dmpname?.value : '');
                        }}
                        style={convertStylesStringToObject(item?.fields[`${getPrefixField('closestyle', isMobile)}`]?.value)}
                      >
                        {item?.fields[`${getPrefixField('closeicon', isMobile)}`]?.value ? (
                          <img
                            className="icon"
                            src={createFilePath(item?.fields[`${getPrefixField('closeicon', isMobile)}`]?.value)}
                            alt="广汽本田"
                          />
                        ) : (
                          <img className="icon" src={createFilePath(fields[`${getPrefixField('closeicon', isMobile)}`]?.value)} alt="广汽本田" />
                        )}
                      </div>
                      {item?.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                        <img
                          className="bg"
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                          src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                          alt="广汽本田"
                        />
                      )}
                      {item?.fields[`${getPrefixField('text')}`]?.value && (
                        <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="next" data-html-item={fields?.nextdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
              <div className="prev" data-html-item={fields?.prevdmp?.value}>
                <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
            </div>
          </>
        )}
      </div>
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields?.placeHolderRoute1?.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
